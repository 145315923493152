.latest-booking {
  margin: 10px 15px 50px 15px;
  padding: 20px 0px 20px 30px;

  overflow: hidden;
  width: 97.6%;
  height: auto;

  background: #f7f7f7;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #2f3073;
    height: 38px;
    background-image: url(//cdn.cnchire.com.au/dashboard/heading-line.svg);
    background-repeat: no-repeat;
    background-position: 0px bottom;
  }

  .more-searches {
    display: block;
    float: right;
    width: 30px;
    z-index: 33443;
    text-align: right;
    font-size: 30px;
    opacity: 0.1;
    margin-right: 20px;
  }
  .more-searches:hover {
    cursor: pointer;
    opacity: 1;
  }

  .description {
    margin-top: 30px;
    margin-bottom: 45px;
  }

  .list-outer-box {
    padding-right: 30px;

    .list-head,
    .list-body {
      display: block;
      margin-top: 10px;
      padding: 12px 10px 20px 30px;
      font-weight: bold;
      width: 100%;
      background: #fff;
      border-radius: 8px;
    }

    .list-head {
      height: 51px;
    }

    .list-body {
      padding-top: 30px;
      font-weight: normal;

      .row {
        margin-bottom: 20px;
      }

      .calendar-btn {
        background-image: url(//cdn.cnchire.com.au/dashboard/calendar-icon.svg);
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: block;
      }

      .vehicle-thumb-container {
        width: 100%;
        .detail {
          float: left;
          padding-left: 5px;
          .vehicle-name {
            padding-left: 10px;
            width: 350px !important;
            height: 20px;
            overflow: hidden;
          }

          .view-detail {
            padding-left: 10px;
          }
        }

        .thumbnail {
          float: left;
          display: block;
          background-repeat: no-repeat;
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 10px;
          background-color: #ffff;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          background-position: center center;
          background-size: contain;
        }
      }

      .user-detail {
        padding-top: 2px;
        float: left;
        font-size: 16px;
        cursor: pointer;
      }
      .user-detail .small {
        font-size: 12px;
      }
      .avatar {
        cursor: pointer;
        margin-right: 5px;
        float: left;
        display: block;
        background-repeat: no-repeat;
        width: 52px;
        height: 52px;
      }

      .more-btn {
        width: 25px;
        display: block;
        height: 22px;
        cursor: pointer;
      }

      .detail-more-popup {
        z-index: 40;
        padding: 20px;
        height: 200px;
        width: 522px;
        margin-top: -10px;
        margin-left: -500px;
        border: 1px solid #e6e6e6;
        position: absolute;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        .close-more {
          cursor: pointer;
          top: 6px;
          right: 10px;
          position: absolute;
        }
      }
    }
  }
}

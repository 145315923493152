.vehicleCardContainer {
  display: flex;
  position: relative;
  margin-right: 5%;
  margin-bottom: 5%;
}

.ribbon {
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  .booked {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background-color: #d9534f;
    position: absolute;
    top: 19px;
    right: -21px;
  }
}

.vehicleName {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-transform: lowercase;
  color: #000000;
}

.pricePerDay {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #1b9fe4;
}

.perDay {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.5;
}

.vehicleCardItemBox {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: row;
  margin-bottom: 2%;
}

.vehicleCardNameBox {
  @extend .vehicleCardItemBox;
  height: 50px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 10px;
  text-transform: capitalize;
}

.vehicleCardItem.searched {
  padding-left: 12px;
  height: 80px !important;
  overflow: hidden;
}

.vehicleCardPriceBox {
  margin-top: 10px;
}

.MuiCardActions-root {
  margin-bottom: 20px !important;
}

.vehicleCardNameBox .vehicleName {
  font-size: 16px;
  text-transform: uppercase;
}

.vehicleCardPriceBox {
  @extend .vehicleCardItemBox;
  margin-bottom: 4%;
}

.vehicleCardItem {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.5;
}

.vehicleCardButtonLink {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  // background: #ff8c3f;
  // border-radius: 3px;
  width: 100%;
  // padding: 3%;
  border: none;
}

.vehicleCardButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #ff8c3f;
  border-radius: 3px;
  width: 90%;
  padding: 3%;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  text-align: center;
  color: #ffffff;
}

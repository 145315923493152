.hireEnquiryContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hireEnquiryForm {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 2%;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  flex-direction: column;
}

.sectionTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  color: #384a5e;
  margin-bottom: 3%;
  margin-top: 2%;
}

.formRow {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin-bottom: 2%;
}

.hireEnquiryInputBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 48%;
  flex-direction: column;
}

.hireEnquiryInputBoxName {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
  margin-bottom: 2%;
}

.hireEnquiryInput {
  width: 100%;
  height: 40px;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  padding: 10px;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
}

.hireEnquiryInputBox2 {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 30%;
  flex-direction: column;
}

.hireEnquiryInputBox3 {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 23%;
  flex-direction: column;
}

.hireEnquiryRadioBox {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.hireEnquiryRadio {
  width: 20%;
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-right: 15%;
}

.hireEnquiryInputBox4 {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}

.orderNotesText {
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  padding: 10px;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
}

.hireEnquirySubmitButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 1%;

  width: 20%;
  background: #ff8c3f;
  border-radius: 3px;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

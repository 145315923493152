.productCardContainer {
  display: flex;
  position: relative;
  margin-left: 4%;
  margin-bottom: 5%;
}

.productName {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.productPrice {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1b9fe4;
}

.productCardButtonLink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  width: 100%;

  position: absolute;
  bottom: 10px !important;
}

.productCardButton {
  justify-content: center;
  align-items: center;
  background: #ff8c3f;
  border-radius: 3px;
  width: 90%;
  padding: 3%;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  text-align: center;
  color: #ffffff;
}

.productCardContainer {
  height: 350px !important;
  position: relative !important;
}

.productCardContainer .MuiTouchRipple-root {
  height: 300px;
}

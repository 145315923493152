.contractContainer {
  .payment-box.disabled {
    opacity: 0.4 !important;
  }
  .payment-box {
    width: 600px;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    background: #e7e7ba85;

    .total-line {
      border-top: 1px solid #333;
      width: 200px;
    }
    .paid-status {
      border: 1px solid #333;
      border-radius: 10px;
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      padding: 0px 10px 0px 10px;
      color: #000;
      background: #d9dedf8c;
      float: right;
    }

    tr.tr-line td {
      border-top: 1px solid #333 !important;
    }
  }

  padding: 20px;
  width: 1200px;
  min-height: 100vh;
  margin: 20px auto 20px auto;
  border-radius: 5px;
  position: relative;

  h1 {
    text-align: left;
  }
  .contract-box iframe {
    width: 100%;
    height: 70vh;
  }

  .tabs {
    .tab-item {
      border: 1px solid #fff;
      float: left;
      background: #eee;
      margin-right: 2px;
      padding: 10px;
    }
    .tab-item.active {
      background: #333;
      color: #fff;
    }
  }

  .clearFix {
    margin-bottom: 40px;
    clear: both;
  }

  table {
    width: 100%;
  }

  .licenseThumb {
    width: 50px;
    height: auto;
    border: 1px solid #eee;
  }

  .error {
    color: red;
    padding: 20px !important;
  }
  .agree-container {
    background: rgb(50, 54, 57);
    padding: 30px;
    font-size: 20px;
    color: #fff;

    width: 100%;
    margin: auto;
    text-align: center;
  }

  button {
    margin: 5px;
  }

  .buttons-bottom {
    button {
      float: right;
    }
  }

  .loader {
    display: block;
    left: 50%;
    margin-left: -40px;
    top: 50%;
    margin-top: -40px;
    position: fixed;
    width: 80px;
    height: 80px;
    z-index: 7777;

    .lds-dual-ring {
      display: inline-block;
      width: 80px;
      height: 80px;
    }
    .lds-dual-ring:after {
      content: ' ';
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .overlay {
    background: #0000004d;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    z-index: 55;
  }
}

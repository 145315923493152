.unavailable-container {
  .unavailable-item {
    width: 500px;
  }
  .unavailable-item .item {
    position: relative;
  }
  .unavailable-item .row.item-error .react-datepicker__input-container input {
    border: 1px solid red;
  }
  .error-message {
    position: absolute;
    margin-top: 10px;
    margin-left: 500px;
  }

  .unavailable-item input {
    width: 200px !important;
  }

  .calendar-icon {
    z-index: 1;
    position: absolute;
    right: 18px !important;
    margin-top: 12px;
  }

  .delete-range-btn {
    cursor: pointer;
    position: absolute;
    margin-top: 12px;
  }
}

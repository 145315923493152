.RegistrationPage {
  background: rgba(247, 247, 247, 0.1);
  color: #000000;
}

.errorMessage {
  color: red;
  font-size: 20px;
}

.registrationContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.regImageFormContainer {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 5%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorAlertRegistation {
  // display: flex;
  // position: relative;
  width: 70%;
  // justify-content: center;
  // align-items: center;
  margin-bottom: 2%;
}

.createUserFormBox {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.regLoginImageIcon {
  width: 27%;
  margin-right: 8%;
}

.regFormContainer {
  width: 34.5%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 6px 6px 47px rgba(0, 0, 0, 0.12);
  padding: 3%;
}

.regTitle {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  // line-height: 50px;
  color: #000000;
  margin: 10%;
  width: 100%;
}

.regInputBox {
  margin: 2%;
  width: 90%;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
}

.regFunctionContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 90%;
  // justify-content: space-between;
}

.checkboxContainer {
  display: flex;
  // justify-content: center;
  align-items: center;
}

.regFunctionText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}

.regSubmitButton {
  background: #ff8c3f;
  border-radius: 3px;
  width: 20%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 2%;
  margin-top: 10%;
}

.regSubmitButtonLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.loginDetails {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.loginDetailsText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  // line-height: 200%;
  text-align: center;
  color: #000000;
}

.loginDetailsLink {
  color: #ff8c3f;
}

.buttonHirerOwner input {
  margin-right: 10px;
}
.buttonHirerOwner {
  font-size: 14px;
  margin: 5px;
  display: inline-block;
  border-radius: 5px;
  width: 50%;
  padding: 10px !important;
  color: #000;
  border: 1px solid #ff8c3f;
}

.buttonHirerOwner.active {
  color: #fff;
  background: #ff8c3f;
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
   	.pageBannerContainer .pg-banner {
	    position: absolute;
	    top: 50%;
	    transform: translate(0, -50%);
	    margin-top: 0;
	    margin-bottom: 0;
	}
	form.hireEnquiryForm {
    	width: 100% !important;
   		padding: 0 25px;
   		margin-top: 0 !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.pageBannerContainer .pg-banner {
	    position: absolute;
	    top: 50%;
	    transform: translate(0, -50%);
	    margin-top: 0;
	    margin-bottom: 0;
	}
	form.hireEnquiryForm {
    	width: 100% !important;
   		padding: 0 25px;
   		margin-top: 0 !important;
	}

}

@media (max-width: 767px) {

	form.hireEnquiryForm {
    	width: 100% !important;
   		padding: 0 25px;
   		margin-top: 0 !important;
	}
	button.hireEnquirySubmitButton {
    	width: 100%;
	}

}

@media (max-width: 480px) {

	.sectionTitle {
    	font-size: 25px !important;
	}
	form.hireEnquiryForm .formRow {
    	display: table;
	}
	form.hireEnquiryForm .formRow .hireEnquiryInputBox, 
	form.hireEnquiryForm .formRow .hireEnquiryInputBox2,
	form.hireEnquiryForm .formRow .hireEnquiryInputBox3 {
    	width: 100% !important;
    	margin-bottom: 15px !important;
	}

}
.navbar-light .navbar-toggler {
    color: #fff !important;
    border-color: #fff !important;
    background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    nav.nav-bar .nav-link-item {
        width: auto;
        margin-right: 16px;
    }
    nav.nav-bar.homepage {
	    margin-top: 20px !important;
    }
    nav.nav-bar .nav-link-item:nth-child(6) {
       margin-right: 0;
    }
    #responsive-navbar-nav {
	    width: 100%;
    }
    .navItems {
	    width: 15%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
	nav .logoImageMobile {
		display: block !important;
		width: 200px;
		position: absolute;
		top: 20px;
		transform: translate(-50%, 0);
		left: 50%;
	}
    #responsive-navbar-nav .nav-link-item span {
        color: #fff !important;
    }
    #responsive-navbar-nav .nav-link-item .sub-menus ul li a, #responsive-navbar-nav .nav-link-item .sub-menus ul li span {
        color: #333 !important;
    }
    #responsive-navbar-nav {
        background-color: #384a5e;
    }
    nav.nav-bar.navbar-light {
        margin-top: 0 !important;
    }
    #responsive-navbar-nav {
        background-color: #384a5e;
        position: fixed;
        top: 0;
        right: -400px;
        transition: right 0.2s ease-in-out;
        width: 40%;
        height: 100vh;
        padding: 40px 25px;
        display: block;
    }
    #responsive-navbar-nav.collapse.show {
        right: 0;
    }
    #responsive-navbar-nav .navbar-nav {
        align-items: unset;
    }
    #responsive-navbar-nav .logoImage {
        display: table;
        margin: 0 auto 10px;
    }
    #responsive-navbar-nav .home-link {
        margin-left: 0 !important;
    }
    #responsive-navbar-nav .car-hire-nav { margin-left: 0; }
    .loginButton, .signUpButton {
        margin-left: 0 !important;
        margin-top: 10px;
        width: 100% !important;
    }
    .custom-dropdown .with-submenu .inner-submenu {
        margin-left: 30px !important;
        padding: 15px !important;
    }
}

@media (max-width: 767px) {
	nav .logoImageMobile {
		display: block !important;
		width: 200px;
		position: absolute;
		top: 20px;
		transform: translate(-50%, 0);
		left: 50%;
	}
    #responsive-navbar-nav .nav-link-item span {
        color: #fff !important;
    }
    #responsive-navbar-nav .nav-link-item .sub-menus ul li a, #responsive-navbar-nav .nav-link-item .sub-menus ul li span {
        color: #333 !important;
    }
    nav.nav-bar.navbar-light {
        margin-top: 0 !important;
    }
    #responsive-navbar-nav {
        background-color: #384a5e;
    }
    nav.nav-bar.navbar-light {
        margin-top: 0 !important;
    }
    #responsive-navbar-nav {
        background-color: #384a5e;
        position: fixed;
        top: 0;
        right: -500px;
        transition: right 0.2s ease-in-out;
        width: 60%;
        height: 100vh;
        padding: 40px 25px;
        display: block;
    }
    #responsive-navbar-nav.collapse.show {
        right: 0;
    }
    #responsive-navbar-nav .navbar-nav {
        align-items: unset;
    }
    #responsive-navbar-nav .logoImage {
        display: table;
        margin: 0 auto 10px;
    }
    #responsive-navbar-nav .home-link {
        margin-left: 0 !important;
    }
    #responsive-navbar-nav .car-hire-nav { margin-left: 0; }
    .loginButton, .signUpButton {
        margin-left: 0 !important;
        margin-top: 10px;
        width: 100% !important;
    }
    .custom-dropdown .with-submenu .inner-submenu {
        margin-left: 30px !important;
        padding: 15px !important;
    }
}

@media (max-width: 480px) {
	nav .logoImageMobile {
		width: 150px;
	}
    #responsive-navbar-nav {
        width: 75%;
    }
    .custom-dropdown .with-submenu .inner-submenu {
        margin-left: 10px !important;
    }
}
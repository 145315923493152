@media (max-width: 767px) {

	.newsContentTitle {
		font-size: 25px !important;
	}

}

@media (max-width: 480px) {

	.newsImageContainer {
		width: 100% !important;
		padding: 0 25px;
		margin-top: 0 !important;
	}
	.newsContent {
		width: 100% !important;
		padding: 0 25px;
	}
	.newsContentTitle {
		font-size: 20px !important;
	}

}
.faqContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faqTitle {
  display: flex;
  position: relative;
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;
}

.faqWrapper {
  display: flex;
  position: relative;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 70%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.faqColumn1 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 48%;
}

.faqColumn2 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 48%;
}

.faqButton {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 8px;

  padding: 2%;
  margin-bottom: 2%;
  flex-direction: row;
}

.faqButtonLabel {
  // width: 95%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.faqButtonArrow {
  display: flex;
  position: relative;
  align-items: flex-end;
}

.accountProfileBox,
.cancellationBox,
.hirersTravellersBox,
.ownersItemsBox,
.insuranceCoverItemsBox,
.portableElectricBrakeHireItemsBox {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5%;
  width: 100%;
}

.faqList {
  padding-left: 15%;
  width: 100%;
}

.faqListItem {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.faqCategoryWrapper {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

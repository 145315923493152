.footerContainer {
  display: flex;
  // position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4%;
  position: absolute;
  width: 100%;
}

.footerDetails {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  // align-items: center;
  padding-left: 12%;
  padding-right: 12%;
}

.logoBox {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  // width: 25%;
  margin-right: 7%;
}

.logo {
  // margin-right: 10%;
}

.destinationBox {
  display: flex;
  position: relative;
  flex-direction: column;
  // width: 25%;
  margin-right: 7%;
}

.destinationTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  // line-height: 145%;
  color: #000000;
  margin-bottom: 5px;
}

.destination {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  color: #000000;
  opacity: 0.6;
}

.supportBox {
  display: flex;
  position: relative;
  flex-direction: column;
  // width: 25%;
  margin-right: 7%;
}

.supportTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  // line-height: 145%;
  color: #000000;
  margin-bottom: 5px;
}

.supportItem {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  color: #000000;
  opacity: 0.6;
}

.followBox {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 25%;
}

.followBoxTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  // line-height: 145%;
  color: #000000;
  margin-bottom: 5%;
}

.socialMediaContainer {
  display: flex;
  position: relative;
  flex-direction: row;
}

.socialMediaLink {
  width: 25%;
  margin-right: 3%;
  margin-bottom: 3%;
}

.socialMediaIcon {
  width: 90%;
}

.socialMediaTerms {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  // line-height: 145%;
  color: #000000;
  opacity: 0.6;
}

.copyrightBox {
  display: flex;
  position: relative;
  padding: 1%;
  // border: #000000;
  width: 100%;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  border: rgba(0, 0, 0, 0.08);
  border-style: solid;
  border-width: 1px;
  border-left: none;
  border-right: none;
  border-bottom: none;

  margin-top: 4%;
}

.copyrightText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  // line-height: 145%;
  text-align: center;
  color: #000000;
  opacity: 0.7;
}

@media (min-width: 768px) and (max-width: 991px) {

	.newsContainer {
		display: table !important;
	}
	.newsCardContainer {
		width: calc(50% - 10px);
		margin-left: 0 !important;
		float: left;
		margin-right: 20px !important;
		margin-bottom: 20px !important;
	}
	.newsCardContainer:nth-child(2n) {
		margin-right: 0 !important;
	}
	.newsCardContainer .MuiPaper-root {
		width: 100%;
	}

}

@media (max-width: 767px) {

	.newsContainer {
		display: table !important;
		width: 100% !important;
		padding: 0 25px;
		margin-top: 0 !important;
	}
	.newsCardContainer {
		width: calc(50% - 10px);
		margin-left: 0 !important;
		float: left;
		margin-right: 20px !important;
		margin-bottom: 20px !important;
	}
	.newsCardContainer:nth-child(2n) {
		margin-right: 0 !important;
	}
	.newsCardContainer .MuiPaper-root {
		width: 100%;
	}
	.dateNewsContainer {
		width: 65% !important;
	}

}

@media (max-width: 480px) {

	.newsCardContainer {
		width: 100%;
		margin: 0 0 20px !important;
	}
	.LatestNews-page .newsCardContainer .MuiPaper-root {
		height: auto !important;
	}

}
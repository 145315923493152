@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.single-product-page .productDetailsBox {
		width: 100%;
		padding: 0 25px;
	}
	.productImageContainer .product-images {
		width: 100% !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.single-product-page .productDetailsBox {
		width: 100%;
		padding: 0 25px;
	}
	.productImageContainer, .productInfoBox {
		width: 50%;
	}
	.productImageContainer .product-images {
		width: 100% !important;
	}

}

@media (max-width: 767px) {

	.single-product-page .productDetailsBox {
		width: 100%;
		padding: 0 25px;
		display: table;
		margin-top: 0;
	}
	.productImageContainer, .productInfoBox {
		width: 100% !important;
	}
	.productImageContainer .product-images {
		width: 100% !important;
	}
	.single-product-page .product-images {
		margin-top: 0 !important;
	}

}

@media (max-width: 480px) {

	.single-product-page .productNameBox, .single-product-page .productPriceBox {
		font-size: 20px;
		line-height: 1.3em;
	}

}
@media (max-width: 767px) {

	.howDoesItWorkTitle {
		width: 100% !important;
		padding: 0 25px !important;
		font-size: 30px !important;
		margin: 0 0 20px !important;
	}
	.howDoesItWorkImage {
		width: 100% !important;
		padding: 0 25px;
	}
	.howDoesItWorkBox {
		width: 100% !important;
		padding: 0 25px;
	}

}

@media (max-width: 480px) {

	.howDoesItWorkTitle {
		font-size: 25px !important;
	}

}
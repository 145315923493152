.searchpage-container {
  background: #fff;
  width: 99%;
  padding: 10px;
  min-height: 500px;
  .filter-selection {
    span {
      background: #eee;
      cursor: pointer;
    }
    span.active {
      font-weight: bold;
    }
    margin-bottom: 20px;
  }
}

.revenues-container {
  border-radius: 5px;
  width: 99%;
  padding: 10px;
  background: #fff;
  .current-balance {
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }
  .current-transfered {
    font-size: 20px;
    text-align: center;
  }

  .transfer-btn {
    float: right;
  }

  .revenues-list {
    border: 1px solid #eee;
    margin-top: 50px;
  }
}

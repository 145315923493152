.latest-booking .list-outer-box .list-body {
    padding: 0;
}
.latest-booking {
    padding: 20px 30px;
    width: 100%;
    margin: 0;
}
.latest-booking .list-outer-box {
    padding-right: 0;
}
.user-container .info-box {
    margin: 12px 0;
}
.user-container .latest-vehicles {
    margin: 10px 0;
    width: 100%;
    padding: 20px 30px;
}
.user-container .latest-vehicles .list-outer-box {
    padding: 0;
}
.user-container .latest-vehicles .list-body {
    padding: 15px;
}
.dashboard-profile-page .user-container .info-box {
    padding-bottom: 15px;
    padding-top: 10px;
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
    .dashboard-admin-bookings-page table thead {
        display: none;
    }
    .dashboard-admin-bookings-page table tr {
        display: grid;
        text-align: center;
        padding: 20px 0;
    }
    .dashboard-profile-page .latest-booking .list-body .row > div {
        flex: none;
        max-width: 100%;
        display: table;
    }
    .dashboard-profile-page .latest-booking .list-body .vehicle-thumb-container .thumbnail, 
    .dashboard-profile-page .latest-booking .list-body .vehicle-thumb-container .detail {
        float: none; display: table; margin: auto; text-align: center;
    }
    .dashboard-profile-page .latest-booking .list-body .vehicle-thumb-container .thumbnail {
        margin: 20px auto;
    }
    .dashboard-profile-page .latest-booking .list-body .status.request-approval {
        display: table !important;
        margin: 0 auto;
    }
    .dashboard-profile-page .latest-booking .list-body .col-1 {
        text-align: center;
        margin: 6px 0;
    }
    .dashboard-profile-page .latest-booking .list-body .avatar, .dashboard-profile-page .latest-booking .list-body .user-detail {
        float: none;
        display: table;
        margin: 8px auto;
        text-align: center;
    }
    .dashboard-profile-page .latest-booking .list-body .detail {
        margin-bottom: 10px !important;
    }
    
}

@media (min-width: 768px) and (max-width: 991px) {

     .dashboard-admin-bookings-page table thead {
        display: none;
    }
    .dashboard-admin-bookings-page table tr {
        display: grid;
        text-align: center;
        padding: 20px 0;
    }
    .dashboard-profile-page .latest-booking .list-body .row > div {
        flex: none;
        max-width: 100%;
        display: table;
    }
    .dashboard-profile-page .latest-booking .list-body .vehicle-thumb-container .thumbnail, 
    .dashboard-profile-page .latest-booking .list-body .vehicle-thumb-container .detail {
        float: none; display: table; margin: auto; text-align: center;
    }
    .dashboard-profile-page .latest-booking .list-body .vehicle-thumb-container .thumbnail {
        margin: 20px auto;
    }
    .dashboard-profile-page .latest-booking .list-body .status.request-approval {
        display: table !important;
        margin: 0 auto;
    }
    .dashboard-profile-page .latest-booking .list-body .col-1 {
        text-align: center;
        margin: 6px 0;
    }
    .dashboard-profile-page .latest-booking .list-body .avatar, .dashboard-profile-page .latest-booking .list-body .user-detail {
        float: none;
        display: table;
        margin: 8px auto;
        text-align: center;
    }
    .dashboard-profile-page .latest-booking .list-body .detail {
        margin-bottom: 10px !important;
    }
    
}

@media (max-width: 767px) {

     .dashboard-admin-bookings-page table thead {
        display: none;
    }
    .dashboard-admin-bookings-page table tr {
        display: grid;
        text-align: center;
        padding: 20px 0;
    }
    .bookingsPageContainer .search-box {
        width: 100% !important;
    }
    .user-container {
        width: 100%;
    }
    .latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail .vehicle-name,
    .latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail .view-detail {
        width: 100% !important;
        padding: 0;
    }
    .dashboard-profile-page .latest-booking .list-body .col-1 {
        display: table;
        margin: 3px auto;
        text-align: center;
    }
    .dashboard-profile-page .user-container .col-12 {
        padding: 0;
    }
    .dashboard-profile-page .user-container .info-box .field-line {
        display: table;
        width: 100%;
        margin-bottom: 15px;
    }
    .user-container .latest-vehicles {
        padding: 20px 15px !important;
    }
    .dashboard-profile-page .user-container img {
        display: table;
        margin: 0 auto 25px;
    }
    
}

@media (max-width: 480px) {
    .latest-booking {
        padding: 15px !important;
    }
    .user-container .latest-booking .list-outer-box .list-body .avatar {
        width: 50px !important;
    }
}
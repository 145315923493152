.testimonialCard1 {
  background-image: url('//cdn.cnchire.com.au/Testimonial 1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  background-size: 100%;
  width: 100%;
  margin: 2.5%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 6%;
}

.testimonialCard2 {
  background-image: url('//cdn.cnchire.com.au/Testimonial 2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  background-size: 100%;
  width: 100%;
  margin: 2.5%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 6%;
}

.ratingContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.messageBox {
  display: block;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 6%;
  margin-right: 20%;
}

.nameBox {
  display: block;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 50%;
  margin-top: 2%;
}

.locationBox {
  display: block;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 50%;
  margin-bottom: 5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

	.aboutUsBody {
		width: 100% !important;
		padding: 0 50px;
	}
	.partnersAndMediaContainer {
		padding: 50px !important;
	}
	.partnersAndMediaTextBox {
		width: 100% !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.aboutUsBody {
		width: 100% !important;
		padding: 0 25px;
	}
	.partnersAndMediaContainer {
		padding: 25px !important;
	}
	.partnersAndMediaTextBox {
		width: 100% !important;
	}

}

@media (max-width: 767px) {

	.aboutUsBody {
		width: 100% !important;
		padding: 0 25px;
		display: table !important;
	}
	.partnersAndMediaContainer {
		padding: 25px !important;
	}
	.aboutUsTitle {
		font-size: 30px !important;
	}
	.aboutUsImage, .aboutUsBodyText {
		width: 100% !important;
	}
	.aboutUsImage {
		margin-bottom: 20px;
	}
	.aboutUsBodyTexBox ul {
		padding: 0;
	}
	.partnersAndMediaTextBox {
		width: 100% !important;
	}
	.partnersAndMediaTextBox ul {
		padding: 0;
		width: 100%;
	}

}

@media (max-width: 480px) {

	.aboutUsTitleBox {
		padding: 0 25px;
		margin-top: 0 !important;
	}	
	.partnersAndMediaText1 {
		width: 100%;
	}
	.aboutUsTitle {
		font-size: 25px !important;
	}

}
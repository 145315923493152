.digital-signature {
  padding: 20px 20px 100px 20px;
  background: #eee;
  border-radius: 10px;
  margin: 20px 0px 20px 0;

  .sigCanvas {
    border: 1px solid #333;
    background: #fff;
  }

  .clear-btn {
    margin: 5px;
  }
  .save-btn {
    margin: 5px;
  }

  .sigContainer {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;
  }

  .signature-buttons {
    position: absolute;
    margin-top: 20px;
    margin-left: 500px;
  }

  .sigPad {
    width: 100%;
    height: 100%;
    border: 1px solid #333;
    background: #fff;
  }

  .buttons {
    width: 100%;
    height: 30px;
  }

  .sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
  }

  .btn-secondary {
    margin: 2px;
  }
}

.experienceContainer {
  display: block;
  position: relative;
  justify-content: center;
  align-items: center;
  background: rgba(47, 48, 115, 0.05);
  /* padding: 8%; */
  padding-top: 3%;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 3%;
}

.experienceTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;
}

.experienceWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1%;
}

.experience {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  width: 20%;
  margin: 2%;
}

.imageIcon {
  width: 100%;
  height: 60%;
  border-radius: 4px;
  margin-bottom: 5%;
}

.productDetailsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.productDetailsBox {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 5%;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  flex-direction: row;
}

.productImageContainer {
  display: flex;
  position: relative;
  width: 40%;

  .product-images {
    width: 400px;
    margin: 20px auto;
    height: auto;
  }
}

.productImageBox {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.productInfoBox {
  width: 55%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.productNameBox {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 52px;
  color: #384a5e;
  margin-bottom: 2%;
}

.ratingAndReviewBox {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 3%;
}

.productRate {
  margin-right: 5%;
}

.productReviewsNum {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.5;
}

.productPriceBox {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 27px;
  color: #1b9fe4;
  margin-bottom: 3%;
}

.productDescriptionBox {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;

  margin-bottom: 3%;
}

.productCodeBox {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 127.5%;
  color: #384a5e;
  margin-bottom: 3%;
}

.productCode {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 127.5%;
  text-decoration-line: underline;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 3%;
}

.productCodeSecondaryTextBox {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 3%;
}

.specificationTexBox {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 127.5%;
  color: #384a5e;
  margin-bottom: 3%;
}

.specificationsBox {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-left: 10%;
  flex-direction: column;
}

.specificationItem {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.specsTable {
  width: 100%;
  margin-bottom: 2%;
}

.specsTableRow {
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.specsTableDataLabel {
  display: flex;
  position: relative;
  width: 50%;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #384a5e;
}

.specsTableData {
  display: flex;
  position: relative;
  width: 50%;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.purchaseButtonLink {
  display: flex;
  width: 100%;
}

.purchaseButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 3%;
  background: #ff8c3f;
  border-radius: 3px;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  text-align: center;
  color: #ffffff;
}

.support-container table thead tr th {
	text-align: center;
	padding-bottom: 10px;
}
.body-top-area {
	margin-bottom: 30px;
}
.support-container table tbody tr {
	text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.support-container table thead tr th:nth-child(2) {
		width: 15%;
	}
	.body-top-area {
		margin-top: 0 !important;
		margin-bottom: 30px;
		padding: 0 20px;
		margin-right: 0 !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.support-container table thead tr th:nth-child(2) {
		width: 15%;
	}
	.body-top-area {
		margin-top: 0 !important;
		margin-bottom: 30px;
		padding: 0 20px;
		margin-right: 0 !important;
	}

}

@media (max-width: 767px) {

	.dashboard-container-layout {
		background-size: cover !important;
	}
	.support-container table thead {
		display: none;
	}
	.body-top-area {
		margin-top: 0 !important;
		margin-bottom: 30px;
		padding: 0 20px;
		margin-right: 0 !important;
	}
	.support-container table tr {
		display: grid;
		width: 100%;
		padding: 20px 15px 15px;
	}
	.support-container table tbody tr td {
		padding-top: 0;
	}
	.support-container div {
		float: none !important;
	}
	.MuiPagination-root {
		display: table;
		margin: auto;
	}
	
}
.howDoesItWorkContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.howDoesItWorkTitle {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 2%;
  justify-content: center;
  align-items: center;
  width: 70%;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;
}

.howDoesItWorkImage {
  width: 70%;
}

.howDoesItWorkBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin-top: 5%;
}

.howDoesItWorkButton {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  padding: 2%;

  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 8px;

  margin-bottom: 2%;
}

.howDoesItWorkButtonLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.hdiwBody1 {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  // margin-left: 2%;
  margin-bottom: 2%;
  flex-direction: column;
}

.hdiwBodyText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 100%;
}

.hdiwBodyUL {
  margin-left: 6%;
  width: 100%;
}

.reviewCardBox {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.reviewCardBoxRow {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.reviewCardNameDateBox {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.reviewCardName {
  margin-top: 1%;
  // width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.reviewCardDate {
  @extend .reviewCardName;
  font-size: 14px;
}

.reviewCardComment {
  @extend .reviewCardName;
  font-size: 16px;
}

.reviewCardDivider {
  margin-top: 3%;
  opacity: 0.15;
  border: 1px solid #000000;
  width: 100%;
  margin-bottom: 3%;
}

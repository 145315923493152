.advanceSearchBox {
  margin-top: 30px !important;
}
.locationCompleter {
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cncHireDetailsContainer {
    position: absolute !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    left: 50% !important;
    margin-top: 50px;
  }
  .advanceSearchBoxForm {
    display: table !important;
    padding: 20px;
    position: relative;
  }
  .searchButton {
    margin-top: 0 !important;
    position: absolute !important;
    top: 41% !important;
    transform: translate(0, -50%);
    right: 23px;
  }
  .locationSearchContainer .searchInput {
    padding: 12px 10px;
  }
  .advanceSearchContainer,
  .locationSearchContainer {
    float: left;
    margin: 0 5px;
  }
  .advanceSearchBoxForm .advanceSearchContainer {
    margin-right: 10px;
  }
  .locationSearchContainer {
    width: 48.5% !important;
  }
  .advanceSearchContainer {
    width: 37% !important;
  }
  .advanceSearchBoxForm .advanceSearchButton {
    float: left;
    width: calc(50% - 7px);
    margin: 10px 10px 5px 0;
  }
  .advanceSearchBoxForm .advanceSearchButton:last-child {
    margin-right: 0;
  }
  .advanceSearchBoxForm .searchButton {
    width: 10%;
    padding: 15px;
  }
  .searchButton .searchIcon {
    width: 60%;
  }
  .advanceSearchBoxContainerForm .firstRowModal,
  .advanceSearchBoxContainerForm .secondRowModal {
    display: table;
  }
  .advanceSearchBoxContainerForm .firstRowModal > div {
    width: calc(50% - 5px);
    float: left;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .advanceSearchBoxContainerForm .secondRowModal > div {
    width: calc(33.33% - 7px);
    float: left;
    margin-right: 10px;
  }
  .advanceSearchBoxContainerForm .firstRowModal div:nth-child(2n) {
    margin-right: 0;
  }
  .advanceSearchBoxContainerForm .secondRowModal div:last-child {
    margin-right: 0;
  }
  .advanceSearchBoxContainerForm .secondRowModal div.input input {
    padding: 6px 10px;
  }
  .advanceSearchBoxContainerForm .dropdown-menu.show {
    width: 100%;
  }
  .membershipContainer .lowerbadgeContainer {
    margin-top: 0;
  }
  .experienceWrapper .experience:first-child {
    padding-top: 24px;
  }
  .testimonialContainer .testimonialCard1,
  .testimonialContainer .testimonialCard2 {
    background-size: cover;
  }
  .earningsContainer .earnings {
    position: relative;
  }
  .earningsContainer .earnings .iconBox {
    position: absolute;
    top: 0;
    display: table;
    margin: auto;
    text-align: center;
  }
  .earningsContainer .iconBox img {
    margin-top: 0;
  }
  .earningsContainer {
    margin-top: 20px;
  }
  .experienceContainer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cncHireDetailsContainer {
    position: absolute !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    left: 50% !important;
    width: 100% !important;
    padding: 25px;
  }
  .advanceSearchBoxForm {
    display: table !important;
    padding: 20px;
    position: relative;
  }
  .searchButton {
    margin-top: 0 !important;
    position: absolute !important;
    top: 42% !important;
    transform: translate(0, -50%);
    right: 23px;
  }
  .locationSearchContainer .searchInput {
    padding: 12px 10px;
  }
  .advanceSearchContainer,
  .locationSearchContainer {
    float: left;
    margin: 0 5px;
  }
  .advanceSearchBoxForm .advanceSearchContainer {
    margin-right: 10px;
  }
  .locationSearchContainer {
    width: 48.5% !important;
  }
  .advanceSearchContainer {
    width: 37% !important;
  }
  .advanceSearchBoxForm .advanceSearchButton {
    float: left;
    width: calc(50% - 7px);
    margin: 10px 10px 5px 0;
  }
  .advanceSearchBoxForm .advanceSearchButton:last-child {
    margin-right: 0;
  }
  .advanceSearchBoxForm .searchButton {
    width: 10%;
    padding: 15px;
  }
  .searchButton .searchIcon {
    width: 40%;
  }
  .advanceSearchBoxContainerForm .firstRowModal,
  .advanceSearchBoxContainerForm .secondRowModal {
    display: table;
  }
  .advanceSearchBoxContainerForm .firstRowModal > div {
    width: calc(50% - 5px);
    float: left;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .advanceSearchBoxContainerForm .secondRowModal > div {
    width: calc(33.33% - 7px);
    float: left;
    margin-right: 10px;
  }
  .advanceSearchBoxContainerForm .firstRowModal div:nth-child(2n) {
    margin-right: 0;
  }
  .advanceSearchBoxContainerForm .secondRowModal div:last-child {
    margin-right: 0;
  }
  .advanceSearchBoxContainerForm .secondRowModal div.input input {
    padding: 6px 10px;
  }
  .advanceSearchBoxContainerForm .dropdown-menu.show {
    width: 100%;
  }
  .membershipContainer .lowerbadgeContainer {
    margin-top: 0;
  }
  .experienceWrapper .experience:first-child {
    padding-top: 24px;
  }
  .testimonialContainer .testimonialCard1,
  .testimonialContainer .testimonialCard2 {
    background-size: cover;
  }
  .experienceContainer {
    padding-top: 50px !important;
    padding-bottom: 60px !important;
  }
  .earningsContainer .earnings {
    position: relative;
  }
  .earningsContainer .earnings .iconBox {
    position: absolute;
    top: 0;
    display: table;
    margin: auto;
    text-align: center;
  }
  .earningsContainer .iconBox img {
    margin-top: 0;
  }
  .earningsContainer {
    margin-top: 20px;
  }
  .productBox .viewProductButtonLink button {
    width: 90%;
    margin-top: 5px;
  }
  .partnersAndMediaContainer .partnerAndMediaItem {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .cncHireDetailsContainer {
    position: absolute !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    left: 50% !important;
    width: 100% !important;
    padding: 25px;
  }
  .advanceSearchBoxForm {
    display: table !important;
    padding: 20px;
    position: relative;
  }
  .searchButton {
    margin-top: 0 !important;
    position: absolute !important;
    top: 42% !important;
    transform: translate(0, -50%);
    right: 23px;
  }
  .locationSearchContainer .searchInput {
    padding: 12px 10px;
  }
  .advanceSearchContainer,
  .locationSearchContainer {
    float: left;
    margin: 0 5px;
  }
  .advanceSearchBoxForm .advanceSearchContainer {
    margin-right: 10px;
  }
  .locationSearchContainer {
    width: 48.5% !important;
  }
  .advanceSearchContainer {
    width: 37% !important;
  }
  .advanceSearchBoxForm .advanceSearchButton {
    float: left;
    width: calc(50% - 5px);
    margin: 10px 10px 5px 0;
  }
  .advanceSearchBoxForm .advanceSearchButton:last-child {
    margin-right: 0;
  }
  .advanceSearchBoxForm .searchButton {
    width: 10%;
    padding: 15px;
  }
  .searchButton .searchIcon {
    width: 100%;
  }
  .advanceSearchBoxContainerForm .firstRowModal,
  .advanceSearchBoxContainerForm .secondRowModal {
    display: table;
  }
  .advanceSearchBoxContainerForm .firstRowModal > div {
    width: calc(50% - 5px);
    float: left;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .advanceSearchBoxContainerForm .secondRowModal > div {
    width: calc(33.33% - 7px);
    float: left;
    margin-right: 10px;
  }
  .advanceSearchBoxContainerForm .firstRowModal div:nth-child(2n) {
    margin-right: 0;
  }
  .advanceSearchBoxContainerForm .secondRowModal div:last-child {
    margin-right: 0;
  }
  .advanceSearchBoxContainerForm .secondRowModal div.input input {
    padding: 6px 10px;
  }
  .advanceSearchBoxContainerForm .dropdown-menu.show {
    width: 100%;
  }
  .membershipContainer .lowerbadgeContainer {
    margin-top: 0;
  }
  .experienceWrapper .experience:first-child {
    padding-top: 24px;
  }
  .testimonialContainer .testimonialCard1,
  .testimonialContainer .testimonialCard2 {
    background-size: cover;
  }
  .experienceContainer {
    padding-top: 50px !important;
    padding-bottom: 60px !important;
  }
  .earningsContainer .earnings {
    position: relative;
  }
  .earningsContainer .earnings .iconBox {
    position: absolute;
    top: 0;
    display: table;
    margin: auto;
    text-align: center;
  }
  .earningsContainer .iconBox img {
    margin-top: 0;
    height: auto;
  }
  .earningsContainer {
    margin-top: 20px;
  }
  .productBox .viewProductButtonLink button {
    width: 90%;
    margin-top: 5px;
  }
  .membershipContainer,
  .experienceContainer,
  .productBox,
  .testimonialBox,
  .vehiclesBanner,
  .earningsBanner {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .membershipContainer img {
    width: 100%;
  }
  .cncHireDetailsContainer .cncHire3 img {
    width: 100% !important;
  }
  .cncHireDetailsContainer .cncHire {
    font-size: 40px;
  }
  .membershipBenefits {
    display: table !important;
    width: 100%;
  }
  .membershipBenefits .benefit {
    width: calc(50% - 36px);
    float: left;
    height: 325px;
    display: table;
  }
  .membershipBenefits .benefit img,
  .experienceWrapper .experience img {
    height: auto;
  }
  .membershipBenefits {
    margin: 20px 0 !important;
  }
  .membershipContainer .lowerbadgeContainer {
    margin-top: 9rem;
    margin-bottom: 20px;
  }
  .membershipTitle,
  .experienceTitle,
  .testimonialTitle,
  .vehiclesTitle,
  .earningsTitle,
  .partnersAndMediaTitle {
    font-size: 30px !important;
  }
  .experienceWrapper {
    display: table;
  }
  .experienceWrapper .experience {
    float: left;
    min-height: 231px;
    width: 100%;
  }
  .productBox > div {
    padding: 0 15px 38px 15px;
  }
  .productBox .productName {
    color: #fff;
  }
  .bannerContainer {
    padding-top: 0 !important;
  }
  .testimonialContainer .messageBox,
  .testimonialContainer .nameBox,
  .testimonialContainer .locationBox {
    margin-left: 0;
    margin-right: 0;
  }
  .testimonialContainer .testimonialCard1,
  .testimonialContainer .testimonialCard2 {
    min-height: 270px;
  }
  .vehiclesContainer {
    display: table;
    width: 100%;
    margin: 30px 0;
  }
  .vehiclesContainer .vehicle {
    width: calc(50% - 27px);
    float: left;
  }
  .vehiclesContainer {
    margin: 30px 0 !important;
  }
  .partnersAndMediaContainer .partnerAndMediaItem {
    margin-bottom: 0;
  }
  .earningsTitle {
    width: 100% !important;
  }
  .partnersAndMediaContainer {
    padding: 25px 25px;
  }
}

@media (max-width: 480px) {
  .cncHireDetailsContainer .cncHire {
    font-size: 24px;
  }
  .locationSearchContainer .searchInput {
    width: 100%;
  }
  .cncHireDetailsContainer,
  .membershipContainer,
  .productBox,
  .experienceContainer,
  .testimonialBox,
  .vehiclesBanner,
  .earningsBanner {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .locationSearchContainer,
  .advanceSearchContainer {
    display: table;
    width: 100% !important;
  }
  .searchButton {
    position: relative !important;
    top: 0 !important;
    transform: none !important;
    right: 0;
    width: 100% !important;
    margin: 0 0 5px !important;
  }
  .searchButton .searchIcon {
    width: 20px;
  }
  .advanceSearchContainer,
  .locationSearchContainer {
    margin-bottom: 10px !important;
  }
  .advanceSearchContainer .dropdown-menu {
    width: 100%;
  }
  .advanceSearchBoxContainerForm .firstRowModal > div {
    display: table;
    width: 100%;
    float: none;
    margin-right: 0;
  }
  .advanceSearchBoxContainerForm .secondRowModal > div {
    width: 100%;
    margin-bottom: 15px;
  }
  .membershipBenefits .benefit {
    display: table;
    width: 100%;
    height: auto;
    float: none;
    margin: 0 0 25px;
  }
  .membershipBenefits .benefit img {
    width: 40%;
    display: table;
    margin: 0 auto 10px;
  }
  .membershipBenefits .benefit .benefitText {
    display: table;
    margin: auto;
  }
  .membershipTitle,
  .experienceTitle,
  .testimonialTitle,
  .vehiclesTitle,
  .earningsTitle,
  .partnersAndMediaTitle {
    font-size: 21px !important;
  }
  .experienceWrapper .experience {
    float: none;
    width: 100%;
    margin: 0 0 25px;
    min-height: auto;
  }
  .productBox {
    display: table !important;
    width: 100%;
  }
  .productBox > div {
    display: table;
    width: 100%;
    margin: 0 0 15px;
    padding: 50px 15px;
    background-position: center center;
  }
  .productBox .viewProductButtonLink button {
    width: 100%;
  }
  .testimonialContainer {
    display: table !important;
    margin: 30px 0;
  }
  .testimonialContainer .testimonialCard1,
  .testimonialContainer .testimonialCard2 {
    margin: 0 0 10px;
    min-height: auto;
  }
  .experienceWrapper .experience:last-child {
    margin-bottom: 0;
  }
  .vehiclesContainer .vehicle {
    width: 100%;
    margin: 0 0 30px;
  }
  .earningsContainer {
    display: table !important;
  }
  .earningsContainer .earnings {
    width: 100%;
    height: auto;
    margin: 0 0 30px;
  }
  .home-page .productBox > div {
    height: 350px;
    position: relative;
    margin-bottom: 0 !important;
  }
  .experienceContainer {
    padding-bottom: 30px !important;
  }
  .home-page .productBox .productName {
    margin-top: 0;
    position: absolute;
    bottom: 90px;
    transform: translate(-50%, 0);
    left: 50%;
    width: 100%;
  }
  .home-page .productBox .viewProductButtonLink {
    position: absolute;
    width: 90%;
    bottom: 23px;
  }
}

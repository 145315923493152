.earningsBanner {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3%;
  padding-bottom: 4%;
  padding-left: 12%;
  padding-right: 12%;
}

.earningsTitle {
  position: relative;
  width: 70%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 145%;
  text-align: center;
  color: #384a5e;
}

.earningsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.earnings {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 25%;
  height: 5%;
  margin: 3%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  justify-content: center;
  align-items: center;
}

.iconBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
}

.earningsIcon {
  width: 30%;
  height: 30%;
  margin-top: -90%;
}

.earningsDescription {
  display: block;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin-top: 30%;
}

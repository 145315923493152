.faqListContainer {
    width: 100%;
    padding: 0 15px;
}
.faqListContainer .searchbox input {
    display: table;
    margin: auto !important;
}
.faqListContainer .item b {
    padding: 0 15px;
}
.faqListContainer .addBtn {
    display: table;
    margin: 25px auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    
    .dashboard-faq-page .add-form-container {
        padding: 0 15px;
        width: 100%;
    }
    
}

@media (min-width: 768px) and (max-width: 991px) {

    .dashboard-faq-page .add-form-container {
        padding: 0 15px;
        width: 100%;
    }
    
}

@media (max-width: 767px) {

    .faqListContainer .searchbox input {
       width: 95%;
     }
    .dashboard-faq-page .add-form-container {
        padding: 0 15px;
        width: 100%;
    }
    
}

@media (max-width: 480px) {

    .add-form-container .input-item input, .add-form-container .input-item select {
        width: 100% !important;
    }
    
}
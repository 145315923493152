.bondclaim-modal {
  .notes-container {
    border: 1px solid #333030;
  }

  input {
    border: 1px solid #333;
    width: 100% !important;
    background: #eeeeee87;
  }

  .form {
    padding: 50px;
  }

  table {
    width: 100%;
  }
  table td {
    padding: 5px;
  }
  table tr.error {
    background: #e9dada;
  }

  .input-deduction {
    width: 150px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.faqWrapper {
		width: 100% !important;
		padding: 0 50px;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.faqWrapper {
		width: 100% !important;
		padding: 0 25px;
	}

}

@media (max-width: 767px) {

	.faqTitle {
		font-size: 30px !important;
		margin-top: 0 !important;
	}
	.faqWrapper {
		width: 100% !important;
		padding: 0 25px;
	}
	.faqColumn1, .faqColumn2 {
		width: 100% !important;
	}

}

@media (max-width: 480px) {

	.faqTitle {
		font-size: 23px !important;
		margin-top: 0 !important;
	}
	.faqWrapper {
		padding: 0 15px;
	}

}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.loginAccountTitle {
		font-size: 25px !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.loginAccountTitle {
		font-size: 25px !important;
	}
	.loginFormContainer {
		padding-bottom: 50px !important;
	}
	.loginSubmitButton.reset-btn {
		width: 90% !important;
		margin-top: 5px;
	}

}

@media (max-width: 767px) {

	.loginAccountTitle {
		font-size: 25px !important;
	}
	.loginFormContainer {
		padding-bottom: 50px !important;
	}
	.loginFormContainer {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.loginSubmitButton.reset-btn {
		width: 90% !important;
		margin-top: 5px;
	}

}

@media (max-width: 480px) {

}
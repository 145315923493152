.regFormContainer input::placeholder {
    color: #333 !important;
    opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.registrationContainer {
		padding: 0 50px;
	}
	.regLoginImageIcon, .regFormContainer {
		width: 50% !important;
	}
	.regSubmitButton {
    	width: 90% !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {
	.registrationContainer {
		padding: 0 50px;
	}
	.regLoginImageIcon, .regFormContainer {
		width: 50% !important;
	}
	.regSubmitButton {
    	width: 90% !important;
	}
	.regFormContainer .regFunctionContainer .checkboxContainer {
		display: flex;
		flex-direction: column;
	}
	.regFormContainer .regFunctionContainer .buttonHirerOwner {
		display: table;
		width: 100%;
	}
	.regSubmitButton {
		margin-top: 5px !important;
	}
}

@media (max-width: 767px) {

	.registrationContainer {
		padding: 0 25px;
	}
	.regLoginImageIcon, .regFormContainer {
		width: 100% !important;
	}
	.regSubmitButton {
    	width: 90% !important;
	}
	.regFormContainer .regFunctionContainer .checkboxContainer {
		display: flex;
		flex-direction: column;
	}
	.regFormContainer .regFunctionContainer .buttonHirerOwner {
		display: table;
		width: 100%;
	}
	.createUserFormBox {
		display: table !important;
	}
	.regSubmitButton {
		margin-top: 5px !important;
	}
	.regLoginImageIcon {
		width: 40% !important;
		display: table;
		margin: 0 auto 30px !important;
	}
	.regImageFormContainer {
		margin-top: 0 !important;
	}

}

@media (max-width: 480px) {

}
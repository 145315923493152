.ContactUs {
  font-family: 'Poppins', sans-serif;
}

.contactUsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.contactUsIconBox {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 5%;
  width: 70%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 3%;
}

.contactUsIconWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
}

.contactUsIcon {
  width: 20%;
}

.contactUsIconDetails1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.contactUsIconDetails2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  opacity: 0.4;
}

.contactUsBody {
  display: flex;
  position: relative;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 3%;
}

.contactUsImage {
  width: 40%;
}

.contactUsBodyFormContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}

.contactUsBodyTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 75px;
  color: #000000;
}

.contactUsBodyTitleGet {
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 75px;
  color: #ff8c3f;
}

.contactUsBodyMessage {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.contactUsForm {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2%;
}

.contactUsFormRow {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2%;
}

.contactUsFormInputBox {
  display: flex;
  position: relative;
  width: 48%;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
}

.contactUsFormDropdown {
  width: 48%;
}

.stateDropdown,
.enquiryDropdown {
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stateDropdownText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
}

.contactUsFormMessageInputBox {
  display: flex;
  position: relative;
  width: 100%;
  height: 100px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
}

.contactUsFormMessageInputBox::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

textarea.error,
input.error {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

.contactUsFormLastRow {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 2%;
}

.contactUsSendButton {
  width: 20%;
  padding: 2%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #ff8c3f;
  border-radius: 3px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

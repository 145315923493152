.dashboard-profile-page .personal-details-container {
	padding: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {

    .dashboard-profile-page .personal-details-container .field-line .col-4, .dashboard-profile-page .personal-details-container .field-line .col-8 {
	    max-width: 50%;
    }
    .dashboard-profile-page .personal-details-container .field-line:nth-child(4) .col-4 {
	    max-width: 33.33333%;
    }
    
}

@media (max-width: 767px) {
    .dashboard-profile-page .personal-details-container .field-line .col-2,
    .dashboard-profile-page .personal-details-container .field-line .col-4, 
    .dashboard-profile-page .personal-details-container .field-line .col-8 {
	    max-width: 100%;
        flex: none;
    }
    .dashboard-profile-page .personal-details-container .field-line input,
    .dashboard-profile-page .personal-details-container .field-line .row.birthdaycomponent {
	    width: 100%;
    }
    .dashboard-profile-page .birthdaycomponent > div {
	    width: 33.33%;
    }
}

@media (max-width: 480px) {

}
.loginContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reset-btn {
  width: 150px !important;
}

.loginErrorMessage {
  color: red;
  font-size: 20px;
}

.imageFormContainer {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 5%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loginImageIcon {
  width: 27%;
  margin-right: 8%;
}

.loginFormContainer {
  width: 34.5%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 6px 6px 47px rgba(0, 0, 0, 0.12);
  padding: 3%;
}

.loginAccountTitle {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  // line-height: 50px;
  color: #000000;
  margin: 10%;
  width: 100%;
}

.loginInputBox {
  margin: 2%;
  width: 90%;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
}

.functionContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}

.rememberContainer {
  // margin-right: 29%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.functionText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}

.forgetPasswordBox {
  display: flex;
  position: relative;
  justify-self: flex-end;
}

.loginSubmitButtonWait,
.loginSubmitButton {
  background: #ff8c3f;
  border-radius: 3px;
  width: 20%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 2%;
  margin-top: 10%;
}

.loginSubmitButtonWait {
  width: auto !important;
}

.loginSubmitButtonLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.signUpDetails {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.singUpDetailsText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  // line-height: 200%;
  text-align: center;
  color: #000000;
}

.signUpDetailsLink {
  color: #ff8c3f;
}

.bannerContainer {
  background-image: url('//cdn.cnchire.com.au/2.png');
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 107%;
  background-position-y: 127%;
  display: block;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 8%;
  // padding-left: 12%;
  // padding-right: 12%;
  padding-bottom: 4%;
  background: #f7f7f7;
}

.productBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-left: 12%;
  padding-right: 12%;
}

.productBanner {
  margin: 3%;
  padding: 3%;
}

.testimonialBox {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 6%;
  padding-left: 12%;
  padding-right: 12%;
}

.testimonialTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;
  margin-bottom: 1%;
}

.testimonialContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 95%;
}

.boxes {
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  margin-top: 80px;
  padding-left: 10px;
  padding-right: 20px;

  .box-item:hover {
    filter: drop-shadow(0px 4px 4px rgba(29, 26, 26, 0.705));
  }

  .box-item {
    cursor: pointer;
    background-repeat: no-repeat;
    padding: 20px;
    margin-left: 0px;
    margin-top: 0px;
    border-radius: 8px;
    width: 100%;
    height: 180px;
    display: block;
    float: left;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    background-position: 30px 58px;

    margin-bottom: 30px;

    .box-value {
      position: absolute;
      font-size: 35px;
      top: 25px;
      right: 40px;
    }

    .box-caption {
      position: absolute;
      font-size: 16px;
      right: 40px;
      bottom: 25px;
    }
  }

  .bookings {
    background-image: url(//cdn.cnchire.com.au/dashboard/box-icon-bookings.svg);
    background-color: #536cfb;
  }

  .pending-booking {
    background-image: url(//cdn.cnchire.com.au/dashboard/box-icon-bookings.svg);
    background-color: #419788;
  }
  .earnings {
    background-image: url(//cdn.cnchire.com.au/dashboard/box-icon-vehicles.svg);
    background-color: #ff2758;
  }

  .hirers {
    background-image: url(//cdn.cnchire.com.au/dashboard/box-icon-hirers.svg);
    background-color: #f28b31;
  }
  .caravans {
    background-image: url(//cdn.cnchire.com.au/dashboard/box-icon-caravans.svg);
    background-color: #aa54fd;
  }
  .owners {
    background-image: url(//cdn.cnchire.com.au/dashboard/box-icon-owners.svg);
    background-color: #419788;
  }

  .locations {
    background-image: url(//cdn.cnchire.com.au/dashboard/box-icon-locations.svg);
    background-color: #ff2758;
  }

  .vehicles {
    background-image: url(//cdn.cnchire.com.au/dashboard/box-icon-vehicles.svg);
    background-color: #7cb342;
  }
}

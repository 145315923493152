.wrapper {
  background-image: url('//cdn.cnchire.com.au/Header Banner.png');
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 0;
  width: 100vw;
  height: 1000px;
  position: relative;
  top: 0px;
}

.cncHireDetailsContainer {
  position: relative;
  top: 30%;
  //left: 15%;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  width: 60%;
}

.cncHire {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 127.5%;
  text-size-adjust: 100%;
  color: #ffffff;
}

.cncHire2,
.cncHire3 {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 127.5%;
  text-align: left;
  text-size-adjust: 100%;
  color: #ffffff;
}

.cncHireDetailsContainer .cncHire {
  text-align: center;
}
.cncHireDetailsContainer .cncHire2 {
  text-align: center;
  font-size: 20px;
  margin: 10px !important;
}
.cncHireDetailsContainer .cncHire3 {
  text-align: center;
  font-size: 25px;
  text-align: center;
  width: 100% !important;
  margin-top: 50px;
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 20px 0px;
  height: auto;
  font-size: 25px;

  img {
    width: 60%;
    height: auto;
  }
}

.searchCaravanButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 33%;
  left: 15%;
  width: 229px;
  height: 50px;
  background: #ff8c3f;
  border-radius: 3px;
  // margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.searchCaravanButtonLink {
  display: flex;
  position: relative;
  margin-top: 2%;
  margin-bottom: 19%;
}

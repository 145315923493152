// @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.btn-primary {
  background-color: #ff8c3f !important;
  border: none !important;
}
.vehicleTypeDropdown.btn-primary {
  background-color: #fff !important;
  border: none !important;
}

/*
.status{  
  text-transform:capitalize;
  min-width: 87px;
  display:block;
  min-height: 22px;  
  background: #7CB342; 
  text-align:center; 
  color:#fff;
  font-size:12px; 
  padding-top:2px;   
  border-radius:5px;
  height:auto;     
}

.status.cancelled{ 
  background:#F28B31!important;
}

.status.booked{ 
  background:#61bdd7!important;
}

.status.rejected{background:#F28B31!important;  }
.status.forfeited{ background:#F28B31!important; }
.status.pending-owner-remark{ background:#fc8769!important; }
.status.pending-full-payment,
.status.pending-final-payment,
.status.pending-approval,
.status.pending-initial-payment{ background:rgba(255, 0, 0, 0.836)!important; }
.status.completed{ }

*/

.dashboard-container-layout {
  table {
    width: 100%;
  }
  tr:hover {
    background: #cde7ee !important;
  }
  tr.even {
    background: rgb(230, 251, 255);
  }
  td {
    vertical-align: top;
    padding: 10px;
  }

  * {
    font-family: 'Poppins', sans-serif;
  }

  .btn {
    margin: 2px !important;
  }

  background: url(//cdn.cnchire.com.au/dashboard/bg.svg) no-repeat top right
    #fff;
  background-repeat: no-repeat;
  //background-position: 0px -1000px;
  //background-size:2200px 2400px;
  // background-size: 100vw 100vh;
  // background-position: 0px -260px;

  .toogle-button-icon,
  .alert-icon,
  .message-icon,
  .more,
  .thumb,
  .search-input {
    height: 50px;
    display: block;
    background: url(//cdn.cnchire.com.au/dashboard/top-icons.svg) no-repeat;
    opacity: 0.9;
  }

  .toogle-button-icon:hover,
  .alert-icon:hover,
  .message-icon:hover,
  .more:hover,
  .thumb:hover,
  .search-input:hover {
    opacity: 1;
  }

  .top-container {
    padding-top: 20px;
    height: 120px;

    .more {
      cursor: pointer;
      float: left;
      margin-right: 10px;
      width: 50px;
      height: 50px;
      background-position: -44px -5px;
    }
    .search-input {
      width: 400px;
      background-position: 370px -5px;
      background-color: #ffffff3b;
      border-radius: 8px;
      border: none;
      padding-left: 20px;
      color: #fff;
    }
    .search-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #ffffff7a;
      opacity: 1; /* Firefox */
    }
    .search-input:focus {
      background: rgba(255, 255, 255, 0.466);
      outline: none;
    }

    .notification-box-overlay {
      position: fixed;
      background: #eeeeee10;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;
      z-index: 9;
    }

    .notification-box {
      border: 1px solid #f7f2f2ab;
      margin-top: 55px;
      margin-left: -15px;
      position: absolute;
      background: #fff;
      width: 200px;
      border-radius: 10px;
      z-index: 11;
      span.arrow-tabon {
        width: 30px;
        height: 25px;
        overflow: hidden;
        position: absolute;
        z-index: 10;
        margin-top: -25px;
        margin-left: 10px;
        svg {
          color: #fff;
          font-size: 50px;
        }
      }

      ul {
        border-radius: 10px;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        li {
          list-style: none;
          color: #333;
          border-bottom: 1px solid #b3abab33;
          font-size: 14px;
          padding: 10px;
          width: 200px;
          overflow: hidden;
        }
        li:hover {
          color: #333;
          background: #eee;
        }
      }
    }

    .topnav-item-container {
      float: right;
      width: auto;
      h4 {
        color: #ff8c3f;
        font-size: 18px;
      }
      .name {
        padding-left: 25px;
      }
      .user-type {
        color: #fff;
        width: 60px;
        padding-right: 14px;
        text-align: right;
      }

      .account-box {
        overflow: hidden;
        padding: 0px;
        width: 100px;
        height: 100px;

        ul {
          padding: 0px;
          margin: 0px;
          width: auto;
          li {
            list-style: none;
            padding: 2px 2px 2px 10px;
            width: auto;
            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }

        .toogle-button-icon {
          background-position: -314px -20px;
          margin-left: 50px;
          width: 20px;
          height: 20px;
        }

        .toogle-button {
          cursor: pointer;
          width: 100%;
          height: 20px;
          overflow: hidden;
          display: block;
          position: absolute;
          margin-top: -20px;
          margin-left: 0px;
        }

        .toogle-button:hover {
          height: 200px;
          width: 200px;
        }

        .inner-nav {
          padding: 10px;
          margin-top: 0px;
          margin-right: 100px;
          background: #ffffff63;
          border-radius: 5px;
        }
      }

      .topnav-item-container .thumb,
      .search-input {
        width: 60px;
        height: 20px;
      }

      .thumb {
        cursor: pointer;
        background-position: -240px 2px;
        width: 57px;
        height: 57px;
      }

      .message-icon {
        cursor: pointer;
        background-position: -177px 0px;
        width: 40px;
      }

      .message-icon i {
        position: absolute;
        margin-top: 12px;
        margin-left: 26px;
        font-size: 10px;
        text-transform: none;
      }

      .alert-icon {
        cursor: pointer;
        background-position: -115px 0px;
        width: 40px;
      }
      .alert-icon i {
        position: absolute;
        margin-top: 12px;
        margin-left: 19px;
        font-size: 10px;
        text-transform: none;
      }
    }
  }

  .sidebar.less {
    display: none;
  }
  .sidebar {
    padding: 20px;
    background: #e6fcf8;
    -webkit-box-shadow: 5px 14px 14px 11px #d2d2d22e;
    -moz-box-shadow: 5px 14px 14px 11px #d2d2d22e;
    box-shadow: 5px 14px 14px 11px #d2d2d22e;
    border-right: 1px solid #e6e4e4;
    border-top-right-radius: 5px;

    .logo {
      display: block;
      width: 100px;
      height: 100px;
      margin: auto !important;
      background: url(//cdn.cnchire.com.au/dashboard/logo.svg) no-repeat;
      width: 100px;
      height: 60px !important;
      background-size: contain !important;
      background-position: 0px -2px !important;
    }

    .inquire-box {
      text-align: center;
      width: 100%;
      font-size: 10px;
      margin-bottom: 30px;
    }

    ul li {
      cursor: pointer;
      padding: 10px;
      list-style: none;
      margin-bottom: 20px;
      font-size: 16px;
      height: 40px;
      border-bottom: 1px solid #cfe4e140;
      span {
        margin-right: 20px;
        width: 100%;
        display: inline-block;
        padding-left: 30px;
        height: 30px;
        background-position: 0px 3px;
      }
      svg {
        display: block !important;
        margin-top: 5px;
        color: #000;
        float: left;
      }
      span {
        color: #000;
        display: block;
        float: left;
        width: 80% !important;
      }
    }
  }

  li:hover {
    background-color: rgba(255, 255, 255, 0.226);
  }

  .main.expanded {
    padding-left: 50px;
  }
  .main {
    min-height: 100vh;

    .body-top-area {
      margin-top: 30px;
      margin-right: 30px;
      color: #fff;
      .text-right {
        font-size: 12px;
        a {
          color: #fff;
        }
        span {
          opacity: 0.5;
        }
      }
    }
  }
}

span.status {
  width: 25px !important;
  height: 25px !important;
  display: inline-block !important;
  margin-bottom: 5px;
  svg {
    width: 100% !important;
    color: #fff !important;
  }
}

.status.request-approval {
  min-width: 25px !important;
  background-color: red !important;
}
.status.failed {
  min-width: 25px !important;
  background-color: red !important;
}
.status.request-approved,
.status.unpaid {
  min-width: 25px !important;
  background-color: #e06800 !important;
}
.status.partial-payment {
  min-width: 25px !important;
  background-color: #dd3333 !important;
}
.status.on-hold {
  min-width: 25px !important;
  background-color: #ffc107 !important;
}
.status.paid,
.status.processing {
  min-width: 25px !important;
  background-color: #3a962e !important;
} /* paid */
.status.onhire {
  min-width: 25px !important;
  background-color: #17a2b8 !important;
}
.status.bond-investigate {
  min-width: 25px !important;
  background-color: #ffc107 !important;
}

.status.completed,
.status.refund,
.status.refund-bond {
  min-width: 25px !important;
  background-color: #3a962e !important;
}
.status.withhold-bond {
  min-width: 25px !important;
  background-color: #dd3333 !important;
}
.status.bond-refunded {
  min-width: 25px !important;
  background-color: #3a962e !important;
}
.status.bond-partially-refunded {
  min-width: 25px !important;
  background-color: #0066bf !important;
}
.status.bond-withheld {
  min-width: 25px !important;
  background-color: red !important;
}
.status.refunded {
  min-width: 25px !important;
  background-color: #0066bf !important;
}
.status.cancelled {
  min-width: 25px !important;
  background-color: #8e8e8e !important;
}

.payout-alert-btn {
  background-color: #dd3333 !important;
  color: #fff;
  font-weight: bold;
  border: rgb(95, 53, 53) 1px solid !important;
  color: #fff !important;
}
.status.status-text {
  min-width: 200px !important;
  border-radius: 5px !important;
  color: #fff !important;
  svg {
    margin-top: 3px;
    margin-left: 10px;
    width: auto !important;
    color: #fff !important;
    float: left;
  }
}

.btn-action {
  width: 200px !important;
  font-size: 13px !important;
}

.bondclaim-modal {
  width: 70vw !important;
  max-width: 70vw !important;
}

.change-password-modal {
  width: 40vw !important;
  max-width: 40vw !important;
}

.common-modal {
  width: 50vw !important;
  max-width: 50vw !important;
}

// @import 'https://cdn.cnchire.com.au/responsive/style.css';
@import '../../styles/responsive/style.css';

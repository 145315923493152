@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.products-page .productsPageDescriptionBox {
		width: 100%;
		padding: 0 50px;
	}
	.products-page .productsContainer {
		width: 100%;
		padding: 0 50px;
	}
	.products-page .productCardContainer {
		width: calc(33.33% - 17px);
		margin-left: 0;
		margin-right: 25px;
	}
	.products-page .productCardContainer:nth-child(3n+3) {
		margin-right: 0;
	}
	.products-page .productCardContainer .productName {
		font-size: 16px;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.products-page .productsPageDescriptionBox {
		width: 100%;
		padding: 0 50px;
	}
	.products-page .productsContainer {
		width: 100%;
		padding: 0 50px;
	}
	.products-page .productCardContainer {
		width: calc(33.33% - 17px);
		margin-left: 0;
		margin-right: 25px;
		height: 370px !important;
	}
	.products-page .productCardContainer:nth-child(3n+3) {
		margin-right: 0;
	}
	.products-page .productCardContainer .productName {
		font-size: 16px;
		line-height: 1em;
	}

}

@media (max-width: 767px) {

	.products-page .productsPageDescriptionBox {
		width: 100%;
		padding: 0 25px;
		margin-top: 0;
	}
	.products-page .productsContainer {
		width: 100%;
		padding: 0 25px;
	}
	.products-page .productCardContainer {
		width: calc(50% - 13px);
		margin-left: 0;
		margin-right: 25px;
		height: 370px !important;
	}
	.products-page .productCardContainer:nth-child(2n+2) {
		margin-right: 0 !important;
	}
	.products-page .productCardContainer:nth-child(3n+3) {
		margin-right: 25px;
	}
	.products-page .productCardContainer .productName {
		font-size: 16px;
		line-height: 1em;
	}
	.products-page .productsPageTitle {
		font-size: 30px;
	}
}

@media (max-width: 480px) {

	.products-page .productsPageTitle {
		font-size: 23px;
	}
	.products-page .productCardContainer {
		width: 100% !important;
		margin: 0 0 25px !important;
	}
	.products-page .productCardContainer .MuiCard-root {
		width: 100%;
	}

}
.bookingsPageContainer {
  background: #fff;
  padding: 20px;

  .edit-booking-btn {
    background: #cecbcb;
    border: 1px solid #333;
    border-radius: 5px;
    margin-right: 10px;
  }
  .search-box {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    border: 1px solid rgba(0, 0, 0, 0.2);
    border: none;
    padding: 10px;
    width: 414px;
    height: 42px;
    background: url(//cdn.cnchire.com.au/dashboard/top-icons.svg) no-repeat
      380px -10px #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .filter-selection {
    span {
      background: #eee;
      cursor: pointer;
    }
    span.active {
      font-weight: bold;
    }
  }
  .confirm-popup {
    border: 1px solid #eee;
    width: 500px;
    height: auto;
    left: 50%;
    margin-left: -250px;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
    padding: 40px;
    background: #fff;
    border-radius: 10px;
    position: fixed;

    h1 {
      margin-bottom: 50px;
    }
    button {
      float: right;
      margin-left: 10px;
    }
    .status-complete {
      color: green;
    }
    .status-approve {
      color: #007bff;
    }
    .status-reject {
      color: #dc3545;
    }
  }

  .bookings-container {
    background: #fff;
    padding: 20px;
    th.vehicle-column {
      width: 200px;
    }
    .amount-paid {
      color: green !important;
    }
    .rejected {
      color: red !important;
    }
    .bold {
      font-weight: bold;
    }
    .detail-info {
      font-size: 12px;
    }
    .pending-status {
      color: red;
    }
    .action-passed {
      color: #333;
    }
    .action-passed.completed {
      color: green;
    }
    .action-current {
      color: red;
    }
  }

  .bookings-container.Owner {
    .approval,
    .remark {
      color: red !important;
      font-weight: bold !important;
    }
    .amount-due {
      color: orange !important;
    }
    .forfeited {
      color: gray !important;
      text-decoration: line-through !important;
    }
  }

  .bookings-container.Hirer {
    .approval,
    .remark {
      color: orange !important;
      font-weight: normal !important;
    }
    .amount-due {
      color: red !important;
      font-weight: bold !important;
    }
    .forfeited {
      color: gray !important;
      text-decoration: line-through !important;
    }
  }
}

.filter-selection {
	margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .reviews-container table thead {
        display: none;
    }
    .reviews-container table tr {
	    display: grid;
	    text-align: center;
	    padding: 20px 0;
    }
    .reviews-container table tr td img.thumbnail {
	     width: 50% !important;
    }
}

@media (max-width: 767px) {

    .reviews-container table thead {
        display: none;
    }
    .reviews-container table tr {
	    display: grid;
	    text-align: center;
	    padding: 20px 0;
    }
    .reviews-container table tr td img.thumbnail {
	     width: 50% !important;
    }
    
}

@media (max-width: 480px) {

     .reviews-container table tr td img.thumbnail {
	     width: 100% !important;
    }
    
}
.dashboard-vehicle-list-page thead tr th {
	padding-bottom: 10px;
}
.dashboard-vehicle-list-page thead tr {
	text-align: center;
}
.dashboard-vehicle-list-page .vehicle-list-container .main-container .item-box {
	height: auto !important;
	padding-bottom: 15px;
}
.dashboard-vehicle-list-page .main-container div:last-child,
.vehicle-list-container.draft-container div:last-child {
	float: none !important;
}
.MuiPagination-root {
	display: table; margin: auto;
}
.vehicle-list-container.pending-container .main-body-container table td:last-child .btn {
	width: auto;
}
.vehicle-list-container .main-body-container table td:last-child .btn {
	width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.dashboard-vehicle-list-page tbody tr td:last-child button:last-child {
		width: 100% !important;
	}
	.dashboard-vehicle-list-page.ehicle-list-container.pending-container tbody tr td:last-child button:first-child, 
	.dashboard-vehicle-list-page.ehicle-list-container.pending-container tbody tr td:last-child button:nth-child(2) {
		width: 47.5% !important;
	}
	.dashboard-vehicle-list-page tbody tr td:last-child button:nth-child(2) {
		margin-right: 0 !important;
	}
	.vehicle-list-container .search-box {
		margin-left: 22px;
	}
	.vehicle-list-container .main-container .thumbnail {
		height: 200px !important; 
	}
	.vehicle-list-container .main-container .MuiRating-root {
		margin: 0 !important;
		transform: translate(-50%, 0);
		left: 50%;
	}
	.vehicle-list-container .main-container .buttons .btn {
		width: 92% !important;
	}
	.vehicle-list-container .main-container .rate {
		font-size: 20px !important;
	}
	.vehicle-list-container.pending-container .main-body-container table td:last-child .btn {
		width: 100%;
	}
	.vehicle-list-container.draft-container table tbody tr td:last-child button {
		width: 100%;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.dashboard-vehicle-draft-page .main div:nth-child(3) a.btn {
		margin-left: 20px !important;
	}
	.dashboard-vehicle-list-page tbody tr td:last-child button {
		width: 100% !important;
	}
	.dashboard-vehicle-list-page tbody tr td:last-child button:first-child, 
	.dashboard-vehicle-list-page tbody tr td:last-child button:nth-child(2) {
		width: 47.5%;
	}
	.dashboard-vehicle-list-page tbody tr td:last-child button:nth-child(2) {
		margin-right: 0 !important;
	}
	.vehicle-list-container .search-box {
		margin-left: 22px;
	}
	.vehicle-list-container .main-container .thumbnail {
		height: 200px !important; 
	}
	.vehicle-list-container .main-container .MuiRating-root {
		margin: 0 !important;
		transform: translate(-50%, 0);
		left: 50%;
	}
	.vehicle-list-container .main-container .buttons .btn {
		width: 92% !important;
	}
	.vehicle-list-container .main-container .rate {
		font-size: 20px !important;
	}
	.vehicle-list-container .col-3 {
		max-width: 50%;
		flex: 33.33%;
	}
	.vehicle-list-container .col-3:nth-child(3n+3) {
		padding-right: 0;
	}
	.dashboard-container-layout .main-body-container {
		overflow-x: auto;
	}
	.dashboard-container-layout .main-body-container table {
		width: 100%;
	}
	.vehicle-list-container.draft-container table tbody tr td:last-child button {
		width: 100%;
	}

}

@media (max-width: 767px) {
    
    .dashboard-createvehicle-page h1 {
        color: #333;
    }
	.dashboard-vehicle-list-page table tr {
		display: grid;
		width: 100%;
	}
	.dashboard-vehicle-list-page table thead {
		display: none;
	}
	.dashboard-vehicle-list-page table tr td {
		padding-top: 0;
	}
	.dashboard-vehicle-list-page table tr {
		display: grid;
		width: 100%;
		padding: 20px 13px 13px;
		text-align: center;
	}
	.dashboard-vehicle-list-page table tr td img {
		width: 50%;
	}
	.dashboard-vehicle-list-page .vehicle-list-container h1 {
		font-size: 25px;
		margin-bottom: 30px;
	}
	.vehicle-list-container .search-box {
		margin-left: 22px;
		width: 73% !important;
		margin-bottom: 0 !important;
		margin-top: 7px;
	}
	.vehicle-list-container .main-container .thumbnail {
		height: 155px !important; 
	}
	.vehicle-list-container .main-container .MuiRating-root {
		margin: 0 !important;
		transform: translate(-50%, 0);
		left: 50%;
	}
	.vehicle-list-container .main-container .buttons .btn {
		width: 92% !important;
	}
	.vehicle-list-container .main-container .rate {
		font-size: 20px !important;
	}
	.vehicle-list-container .col-3 {
		max-width: 50%;
		flex: 33.33%;
	}
	.vehicle-list-container .col-3:nth-child(3n+3) {
		padding-right: 15px;
	}
	.vehicle-list-container.draft-container table tbody tr td:last-child button {
		width: 100%;
	}
	.vehicle-list-container.draft-container table tbody tr {
		display: grid;
		width: 100%;
		text-align: center;
	}
	.vehicle-list-container.draft-container thead {
		display: none;
	}
	.vehicle-list-container.draft-container .results {
		padding-right: 0;
		margin-bottom: 20px;
	}
	.dashboard-vehicle-draft-page .main div:nth-child(3) a.btn {
		margin-left: 20px !important;
	}
	.dashboard-vehicle-draft-page table img.thumbnail {
		width: 50%;
	}
    .dashboard-createvehicle-page .subcontent .col-2, .dashboard-createvehicle-page .subcontent .col-10 {
        flex: none;
        max-width: 100%;
    }
    .create-vehicle-container .line-item textarea {
        width: 100%;
    }
    .create-vehicle-container button.btn-secondary {
        margin-left: 30px !important;
    }
    .create-vehicle-container .thumbnails .thumbnail-item {
        display: table;
        width: 100%;
        margin: 15px 0 15px 0 !important;
        padding: 15px;
    }
    .create-vehicle-container .thumbnails .thumbnail-item .delete-thumb {
        top: 15px;
        right: 15px;
    }
    .create-vehicle-container .tab-container .tab-item {
        width: 100%;
    }
    .create-vehicle-container .line-item {
        margin: 15px;
    }
    .unavailable-container .unavailable-item {
        width: 100%;
    }
    .unavailable-container .unavailable-item .item {
        flex: none;
        max-width: 100%;
    }
    .create-vehicle-container table tr {
        display: grid;
    }
    .create-vehicle-container table td, .create-vehicle-container .line-item .date-applied, 
    .create-vehicle-container .line-item input[type=text], 
    .create-vehicle-container .line-item select {
        width: 100% !important;
        padding: 0 !important;
    }
    .create-vehicle-container .section-0 .line-item:last-child button.btn-secondary {
        margin-left: 0 !important;
        width: 100%;
    }
    .dashboard-createvehicle-page .subcontent .col-10 .col-4 {
        flex: none;
        max-width: 100%;
    }
    .create-vehicle-container .addon-amenities {
        width: 100% !important;
    }
    .vehicleBannerContainer {
        min-height: auto;
    }
    .create-vehicle-container .section-0 .line-item:nth-child(1) .react-datepicker-wrapper {
        display: block;
        padding-left: 15px;
    }
    .create-vehicle-container .section-0 .line-item:nth-child(1) .react-datepicker-wrapper input {
        height: 45px;
    }
    .dashboard-vehicle-list-page .vehicle-list-container .search-box {
        display: table;
        width: 60% !important;
        margin-bottom: 20px !important;
    }
    .vehicle-list-container .order-list {
	    top: 0;
    }

}

@media (max-width: 480px) {

	.dashboard-vehicle-list-page .vehicle-list-container h1 {
		font-size: 20px;
	}
	.dashboard-vehicle-list-page table tr td img {
		width: 100%;
	}
	.vehicle-list-container .col-3 {
		max-width: 100%;
		flex: none;
	}
	.vehicle-list-container .main-container .title {
		height: auto;
	}
	.dashboard-vehicle-draft-page table img.thumbnail {
		width: 100%;
	}
    .dashboard-vehicle-list-page .main div:nth-child(3) {
	    float: none !important;
	    width: 100%;
	    margin-right: 0 !important;
	    padding: 0 15px;
    }
    .dashboard-vehicle-list-page .main div:nth-child(3) a.btn-secondary {
	    width: 100%;
    }
    .dashboard-vehicle-list-page .main.col-10 .btn.btn-primary {
	    width: calc(100% - 35px);
	    display: table;
	    margin: 10px auto !important;
    }

}
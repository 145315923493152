.BookingConfirmation {
  font-family: 'Poppins', sans-serif;
}

.bookingConfirmationContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
}

.confirmationTitleBox {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 2%;
  width: 100%;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}

.confirmationTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: left;
  color: #384a5e;
  margin-left: 5.5em;
}

.bookingConfirmationBody {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 70%;
  padding-bottom: 3%;
}

.couponBody {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 70%;
  padding-bottom: 6%;
}

.cartTotal {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 70%;
  padding-bottom: 6%;
  margin-top: 6em;
  margin-bottom: 8em;
}

.cartTotalBody {
  position: absolute;
  width: 400px;
  background: rgba(224, 235, 237, 0.4);
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
}

.cartTotalTitle {
  font-weight: 500;
  font-size: 25px;
  line-height: 127.5%;
  color: #384a5e;
}

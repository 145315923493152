.reply-message-container {
  textarea {
    width: 100%;
    height: 300px;
  }
  background: rgba(238, 238, 238, 0.514);
  padding: 10px;
}

.is_read {
  font-weight: normal;
}
.is_not_read {
  font-weight: bold;
}

.message-sent {
  padding: 20px;
  h2 {
    text-align: center;
  }
}

.read-message-container {
  background: rgba(238, 238, 238, 0.514);
  padding: 10px;

  .read-message-content {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-left: 6px;
    margin-right: 6px;
  }
}

.inbox-container {
  .linkbutton {
    background: none;
    border: none;
    outline: none;
    color: #007bff;
    font-weight: bold;
    padding: 0px !important;
  }
  border: 1px solid #eee;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
}

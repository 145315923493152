.sales-stats {
  margin: 10px 15px 50px 15px;
  padding: 20px 0px 20px 30px;
  overflow: hidden;
  width: 97.6%;
  min-height: 117px;
  background: #f7f7f7;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #2f3073;
    height: 38px;
    background-image: url(/Assets/images/dashboard/heading-line.svg);
    background-repeat: no-repeat;
    background-position: 0px bottom;
  }

  .highcharts-point {
    width: 38.5px !important;
  }

  .highcharts-grid {
    display: none !important;
  }

  .chart-legend {
    background: url(https://cdn.cnchire.com.au/dashboard/sales-report-legend.svg)
      no-repeat;
    height: 70px;
    width: 500px;
    position: absolute;
    z-index: 9999;
    margin-top: -30px;
    margin-left: 200px;

    .revenue-legend {
      width: 200px;
      position: absolute;
      left: 78px;
      color: #536cfb;
      font-size: 16px;
    }
    .commision-legend {
      width: 140px;
      position: absolute;
      right: 0px;
      color: #419788;
      font-size: 16px;
    }
  }
}

.vehiclesBanner {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 6%;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 4%;
}

.vehiclesTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;
}

.vehiclesContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1%;
}

.vehicle {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  width: 20%;
  margin: 2.5%;
}

.vehicleIconContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 2%;
  background: rgba(255, 140, 63, 0.07);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 5%;
}

.vehicleIcon {
  width: 70%;
}

.bank-container {
  background: #fff;
  padding: 20px;

  .note-info {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 10px;
    font-size: 14px;
  }

  border-radius: 5px;
  width: 99%;
  min-height: 500px;
  padding: 20px 10px;
  background: #fff;

  .form-line {
    padding: 5px;

    .caption-box {
      float: left;
      width: 150px;
      margin-right: 10px;
    }
    .input-box {
      float: left;
    }
    clear: both;
  }
  .form-line input {
    padding: 2px;
    border-radius: 20px;
  }
}

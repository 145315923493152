.allVehiclesContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  .searchContainer {
    width: 70%;
    position: relative !important;
    z-index: 222;
  }
}

.allVehiclesTitle {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 5%;
  width: 55%;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;
}

.vehicleListingsContainer {
  position: relative;
  margin-bottom: 5%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  width: 900px;
  display: block;
  height: auto;

  .vehicleCardContainer {
    width: 250px !important;
    float: left;
  }
}

.allVehiclesBody {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin-top: 5%;
}

.vehicleListPaginationBox {
  display: flex;
  position: relative;
  margin-bottom: 5%;
  justify-content: center;
  align-items: center;
  width: 92.6%;
  flex-direction: column;
}

.alertMsgContainer {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

div.alert-heading.h4 {
  text-transform: capitalize !important;
  margin-bottom: 20px;
  font-size: 40px !important;
}
.alertMsgContainer .alert {
  margin-left: 8%;
  width: 30%;
  border-radius: 15px;
  border: none !important;
  background: none !important;
}

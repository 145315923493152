.productBanner1 {
  background-image: url('//cdn.cnchire.com.au/Banner 1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  background-size: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 44%;
  margin: 2%;
  justify-content: center;
  align-items: center;
}

.productBanner2 {
  background-image: url('//cdn.cnchire.com.au/Banner 2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  background-size: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 44%;
  margin: 2%;
  justify-content: center;
  align-items: center;
}

.productName {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
  margin-top: 70%;
}

.viewProductButtonLink {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.viewProductButton {
  background: #2f3073;
  border-radius: 3px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 7%;
  border: none;
  padding: 2.3%;
  width: 38%;
}

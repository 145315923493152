/*
    768x435
*/

//100%: 100%;
//435px: 435px;

.vehicleBannerContainer {
  border-top: 1px solid #fff !important;
  display: block;
  position: relative;
  padding: 0px;
  overflow: hidden !important;
  margin-top: 0px;
  margin-bottom: 60px;
  min-height: 600px;
  height: auto;

  .slide-banner-container {
    width: 40vw !important;
    height: 435px;
    overflow: hidden;
    margin: 0px;
    white-space: nowrap;

    .animate-0.reverse {
      animation: rslide0 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-0.forward {
      animation: slide0 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-1.reverse {
      animation: rslide1 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-1.forward {
      animation: slide1 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-2.reverse {
      animation: rslide2 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-2.forward {
      animation: slide2 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-3.reverse {
      animation: rslide3 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-3.forward {
      animation: slide3 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-4.reverse {
      animation: rslide4 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-4.forward {
      animation: slide4 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-5.reverse {
      animation: rslide5 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-5.forward {
      animation: slide5 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-6.reverse {
      animation: rslide6 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-6.forward {
      animation: slide6 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-7.reverse {
      animation: rslide7 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-7.forward {
      animation: slide7 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-8.reverse {
      animation: rslide8 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-8.forward {
      animation: slide8 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-9.reverse {
      animation: rslide9 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-9.forward {
      animation: slide9 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-10.reverse {
      animation: rslide10 0.5s forwards !important;
      animation-delay: 2s;
    }
    .animate-10.forward {
      animation: slide10 0.5s forwards !important;
      animation-delay: 2s;
    }

    @keyframes slide0 {
      0% {
        transform: translateX(calc(0px - (100% * 0)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 1)));
      }
    }
    @keyframes slide1 {
      0% {
        transform: translateX(calc(0px - (100% * 1)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 2)));
      }
    }
    @keyframes slide2 {
      0% {
        transform: translateX(calc(0px - (100% * 2)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 3)));
      }
    }
    @keyframes slide3 {
      0% {
        transform: translateX(calc(0px - (100% * 3)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 4)));
      }
    }
    @keyframes slide4 {
      0% {
        transform: translateX(calc(0px - (100% * 4)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 5)));
      }
    }
    @keyframes slide5 {
      0% {
        transform: translateX(calc(0px - (100% * 5)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 6)));
      }
    }
    @keyframes slide6 {
      0% {
        transform: translateX(calc(0px - (100% * 6)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 7)));
      }
    }
    @keyframes slide7 {
      0% {
        transform: translateX(calc(0px - (100% * 7)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 8)));
      }
    }
    @keyframes slide8 {
      0% {
        transform: translateX(calc(0px - (100% * 8)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 9)));
      }
    }
    @keyframes slide9 {
      0% {
        transform: translateX(calc(0px - (100% * 9)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 10)));
      }
    }
    @keyframes slide10 {
      0% {
        transform: translateX(calc(0px - (100% * 10)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 11)));
      }
    }

    @keyframes rslide0 {
      0% {
        transform: translateX(calc(0px - (100% * 2)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 1)));
      }
    }
    @keyframes rslide1 {
      0% {
        transform: translateX(calc(0px - (100% * 3)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 2)));
      }
    }
    @keyframes rslide2 {
      0% {
        transform: translateX(calc(0px - (100% * 4)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 3)));
      }
    }
    @keyframes rslide3 {
      0% {
        transform: translateX(calc(0px - (100% * 5)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 4)));
      }
    }
    @keyframes rslide4 {
      0% {
        transform: translateX(calc(0px - (100% * 6)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 5)));
      }
    }
    @keyframes rslide5 {
      0% {
        transform: translateX(calc(0px - (100% * 7)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 6)));
      }
    }
    @keyframes rslide6 {
      0% {
        transform: translateX(calc(0px - (100% * 8)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 7)));
      }
    }
    @keyframes rslide7 {
      0% {
        transform: translateX(calc(0px - (100% * 9)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 8)));
      }
    }
    @keyframes rslide8 {
      0% {
        transform: translateX(calc(0px - (100% * 10)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 9)));
      }
    }
    @keyframes rslide9 {
      0% {
        transform: translateX(calc(0px - (100% * 11)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 10)));
      }
    }
    @keyframes rslide10 {
      0% {
        transform: translateX(calc(0px - (100% * 12)));
      }
      100% {
        transform: translateX(calc(0px - (100% * 11)));
      }
    }

    div.remove-animation {
      animation: none;
    }
    div.slide-banner {
      z-index: 222;
    }
    div.slide-banner {
      width: 100%;
      height: 435px;
      display: inline-block;
      white-space: nowrap;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #f0f0f0;
      transition: all 0.5s;
    }
    div.slide-banner:hover {
      transform: scale(1.05) !important;
    }

    .slide-bottons {
      display: none;
      width: 100%;
      text-align: center;
      height: 30px;
      position: absolute;
      z-index: 77788;
      bottom: 150px;
      .slide-button-item {
        display: inline-block;
        margin-right: 10px;
        transform: scale(0.5);
        color: #fe8b3fbd;
        opacity: 0.5;
      }
      .slide-button-item.active,
      .slide-button-item:hover {
        opacity: 1;
        cursor: pointer;
        transform: scale(0.8);
      }
    }
  }

  .arrownav-container {
    z-index: 444;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 200px;
    position: absolute;
    color: #fff;
    width: 100% !important;

    .button-left,
    .button-right {
      background-image: url('//cdn.cnchire.com.au/banner/arrows.png');
      background-repeat: no-repeat;
      width: 35px;
      height: 35px;
      cursor: pointer;
      opacity: 1;
      border-radius: 5px;
      overflow: hidden;
    }

    .button-left:hover,
    .button-right:hover {
      border: 1px solid #ffffff69;
    }

    .button-left.disabled,
    .button-right.disabled {
      cursor: default !important;
      opacity: 0.2 !important;
    }

    .button-left {
      background-position: top right;
    }

    .button-right {
      background-position: top left;
      float: right;
    }
  }

  .thumbnails-container {
    margin-top: -3px;
    padding: 10px;
    height: auto;
    width: 100%;
    display: block;
    overflow: hidden;
    .col-3 {
      margin: 0px;
      padding: 5px;
    }

    .thumb-item {
      display: block;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      //background-color:#f0f0f0;
      width: 100%;
      height: 115px !important;
      cursor: pointer;
    }

    .thumb-item.active {
      cursor: default;
      border: 2px solid #fa8400;
    }
  }
}

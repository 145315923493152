.pending-container {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}

.vehicle-list-container {
  .filter-selection {
    span {
      background: #eee;
      cursor: pointer;
    }
    span.active {
      font-weight: bold;
    }
  }

  margin-top: 37px;
  position: relative;
  width: 98.5%;

  .btn.draft {
    color: #333 !important;
    width: 55px !important;
    background: #eee !important;
  }

  .btn.draft:hover {
    background: #333 !important;
    color: #fff !important;
  }

  .search-box {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    border: 1px solid rgba(0, 0, 0, 0.2);
    border: none;
    padding: 10px;
    width: 414px;
    height: 42px;
    background: url(//cdn.cnchire.com.au/dashboard/top-icons.svg) no-repeat
      380px -10px #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .order-list {
    cursor: pointer;
    top: 10px;
    right: 0px;
    display: block;
    position: absolute;
    width: 84px;
    height: 43px;
    background: url(//cdn.cnchire.com.au/dashboard/order-types.svg) no-repeat;
    overflow: hidden;
    border-radius: 8px;

    .selected-thumbnail {
      width: 42px;
      position: absolute;
      top: 0px;
      height: 50px;
      background: #71c6d23d;
    }

    .selected-list {
      width: 42px;
      right: 0px;
      position: absolute;
      top: 0px;
      height: 50px;
      background: #71c6d23d;
    }
  }

  .top-button-container {
    margin-top: 20px;
    margin-bottom: 20px;
    button {
      float: right;
    }
  }
  .main-container {
    padding: 20px;
    background: #f7f7f7;
    border-radius: 8px;

    .results {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-align: right;
      color: #ff2758;
    }

    .item-box {
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
      margin-bottom: 20px;
      width: 100%;
      height: 595px;
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
    }

    .item-box .item-box-content {
      padding: 10px;
    }

    .thumbnail {
      background-size: cover;
      width: 100%;
      height: 265px;
    }
    td .thumbnail {
      width: 150px !important;
      height: 150px !important;
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
      height: 80px;
      .oneline {
        height: 30px;
        overflow: hidden;
        display: block;
      }
    }

    .status {
      display: inline-block;
    }
    .content {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      height: 50px;
      overflow: hidden;
    }

    .MuiRating-root {
      margin: auto;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .rating {
      margin-top: 10px;
      margin-bottom: 15px;
      display: block;
      height: 20px;
      width: 100%;
      background: url(//cdn.cnchire.com.au/dashboard/rating.svg) no-repeat
        center center;
    }

    .rate {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
    }

    .pending-approval-status {
      margin-top: 10px;
      margin-bottom: 15px;
      height: 20px;
      display: inline-block;
      width: auto;
      background: #eee;
      text-align: center;
      padding: 0px 10px 0px 10px;
      color: red;
      border-radius: 10px;
      font-size: 12px;
    }
    .buttons {
      width: 100%;
      margin-top: 10px;
      text-align: center;
    }

    .buttons {
      .btn {
        width: 112.28px;
        height: 50px;
        left: 412.68px;
        top: 1491px;
        color: #fff;

        background: #ff8c3f;
        border-radius: 3px;

        .btn:hover {
          background: #efa472;
        }
      }
    }
  }
}

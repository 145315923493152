.payment-details-container-popup {
  border: 1px solid #eee;
  width: 500px;
  height: auto;
  top: 100px;
  left: 50%;
  margin-left: -250px;
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  padding: 40px;
  background: #fff;
  border-radius: 10px;
  position: fixed;
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  .error {
    border: 1px solid red !important;
  }

  h1 {
    margin-bottom: 50px;
  }
  button {
    float: right;
    margin-left: 10px;
  }

  .field-line {
    padding-bottom: 30px;

    select,
    input {
      width: 200px;
      padding: 5px;
      border: 1px solid #c1c1c1;
      border-radius: 10px;
    }
  }
}

.payment-details-container {
  .note-info {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 10px;
    font-size: 14px;
  }

  .cardType {
    text-transform: capitalize;
  }
  .expMonth {
    width: 150px !important;
    margin-right: 10px;
  }
  .expYear {
    width: 100px !important;
  }

  .thankyou-body {
    padding: 20px;
    text-align: center;
  }
  .cardItem.selected {
    background: #eee !important;
  }

  .paynow-btn {
    float: right !important;
    display: block !important;
    padding: 10px !important;
  }

  padding: 100px;
  background: #fff;

  .inner-container {
    width: 100%;
    margin: auto;
  }

  h2 {
    background: #eee;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    text-align: left;
  }

  .payment-box-body {
    border: 1px solid #3333 !important;
    margin: auto;
    width: 100%;
    border-radius: 10px;
    padding: 50px;
    min-height: 250px;

    .row {
      margin-bottom: 15px;
    }

    button.bottom-buttons {
      margin: 5px;
      float: right;
    }
  }

  .cardItem:hover {
    background: #eee;
  }
  .add-payment-button,
  .cardItem {
    background: #fdfdfd;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);

    padding: 20px;
    border-radius: 20px;
    margin: 10px;
    border: 2px solid #eee;
    width: 300px !important;
    height: 150px !important;
    float: left;
    cursor: pointer;
  }

  .add-payment-button {
    font-size: 20px;
  }
}

.transaction-container {
  background: #fff;
  padding: 20px;
  th.vehicle-column {
    width: 200px;
  }
  td {
    vertical-align: top;
  }
  .amount-paid {
    color: green !important;
  }
  .rejected {
    color: red !important;
  }
  .bold {
    font-weight: bold;
  }
  .detail-info {
    font-size: 12px;
  }
  .pending-status {
    color: red;
  }
  .action-passed {
    color: #333;
  }
  .action-passed.completed {
    color: green;
  }
  .action-current {
    color: red;
  }
}

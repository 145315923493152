a:hover {
  text-decoration: none !important;
}
.nav-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  margin-top: 2%;
  position: relative !important;
  height: 110px;
}

.navbar.homepage {
  position: absolute !important;
  margin-top: 70px !important;
}

i.icon {
  padding-left: 3px !important;
}
.welcome-link {
  position: absolute;
  margin-top: -90px;
  margin-right: -1130px;
}

@media only screen and (max-width: 600px) {
  .welcome-link {
    display: none !important;
  }
}

.nav-link-item {
  width: 150px;
  cursor: pointer;
}

.inquiries-nav {
  margin: 0px;
  width: 0px;
}
.home-link {
  margin-left: 50px !important;
}

.car-hire-nav {
  width: 120px;
  margin-left: -10px;
}

.custom-dropdown {
  height: auto;
  overflow: hidden;
  a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
  }

  li {
    margin-bottom: 10px;
    width: 200px;
  }
  .vehicle-type {
    width: 200px;
  }
  .vehicle-type svg {
    float: right;
    margin-right: 10px;
  }
  .inner-submenu {
    box-shadow: 0 0 1px #d5d5d5;
    width: 220px;
    background: #fff;
    border: 1px solid #eee;
    display: none;
    position: absolute;
    margin-left: 193px !important;
    margin-top: -20px !important;
    border-radius: 5px;
    z-index: 13;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 10px 5px 5px 7px;
  }
  .sub-menus ul {
    padding: 5px;
    margin: 0px;
  }
  .sub-menus ul li {
    list-style: none;
  }
  .sub-menus {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: #fff;
    border-radius: 5px;
    padding: 10px 5px 5px 7px;
    position: absolute;
    display: none;
    z-index: 10;
  }
  .with-submenu:hover .inner-submenu {
    display: block;
  }
  .sub-menus li:hover {
    background: #f7f2f2;
  }
  .sub-menus li:hover a {
    color: #333;
  }
}
.custom-dropdown:hover .sub-menus {
  display: block;
}

.navItemSpaceAdjustment {
  width: 90px !important;
}
.logoImage {
  width: 146px;
}

.navWrapper {
  // margin: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navItems {
  text-align: center;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 8%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.linkText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.loginButtonLink {
  margin-left: 2%;
  margin-right: 1%;
}

.loginButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 123px;
  height: 45px;
  background: #ff8c3f;
  border-radius: 3px;
  border-style: none;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}

.signUpButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 123px;
  height: 45px;
  background: #ffffff;
  border-radius: 3px;
  border-style: none;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ff8c3f;
  border: 1px solid #ff8c3f;
}

.dropdown-toggle::after {
  display: none;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.dropRight {
  padding: 0.25rem 1rem;
  @extend .navDropdownItem;
}

.navDropdownItem {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
}

button:focus {
  outline: none;
}

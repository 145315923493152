.AdvanceSearchModal {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

.modalBody {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.firstRowModal {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 91%;
  margin: 2%;
}

.travellersBox,
.minPriceBox,
.maxPriceBox,
.hireTypeBox {
  width: 23%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
}

.travellersDropdown,
.minPriceDropdown,
.maxPriceDropdown,
.hireTypeDropdown {
  width: 100%;
}

.travellersDropdown,
.minPriceDropdown,
.maxPriceDropdown,
.hireTypeDropdown,
.minYearDropdown,
.maxYearDropdown,
.keywordsInputBox {
  background: #f7f7f7;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: rgba(0, 0, 0, 0.3);
  text-align: left;
}

.travellersDropdown,
.minPriceDropdown,
.maxPriceDropdown,
.hireTypeDropdown,
.minYearDropdown,
.maxYearDropdown {
  padding: 5%;
}

.secondRowModal {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 91%;
  margin: 2%;
}

.minYearBox,
.maxYearBox {
  width: 23%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
}

.keywordsBox {
  width: 46%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
}

.minYearDropdown,
.maxYearDropdown,
.keywordsInputBox {
  width: 100%;
}

.keywordsInputBox {
  border: 0;
  border-style: none;
}

.ui.input > input {
  border: none;
}

.thirdRowModal {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
}

.resetButtonModal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  border-radius: 3px;
  width: 15%;
  margin-right: 3%;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
}

.searchButtonModal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  background: #ff8c3f;
  border-radius: 3px;
  width: 15%;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  color: #ffffff;
}

.keywordsInput {
  background: #f7f7f7 !important;
}

.advanceSearchContainer .dropdown-toggle::after,
.locationSearchContainer .dropdown-toggle::after {
  color: #fff0 !important;
}

.vehicleTypeDropdown.btn-primary {
  text-align: left !important;
}

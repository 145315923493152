.hirers-container {
  background: #fff;
  width: 99%;
  padding: 10px;
  .filter-selection {
    span {
      background: #eee;
      cursor: pointer;
    }
    span.active {
      font-weight: bold;
    }
  }
  .login-btn {
    width: 200px;
  }

  .search-box {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    border: 1px solid rgba(0, 0, 0, 0.2);
    border: none;
    padding: 10px;
    width: 414px;
    height: 42px;
    background: url(//cdn.cnchire.com.au/dashboard/top-icons.svg) no-repeat
      380px -10px #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    font-size: 16px;
  }
}

.chat-list-box {
  background: #fff;
  height: 520px;
  width: 300px;
  position: fixed;
  bottom: 0px;
  right: 0px;

  opacity: 1;
  border: 1px solid #919090 !important;
  border-bottom: none !important;

  .chat-box {
    background: none !important;
    .chat-footer {
      bottom: 0px !important;
    }
  }

  .chat-body {
    // padding: 10px;
    height: 460px;
    overflow-y: auto !important;
    overflow-x: hidden;
  }
}

.chat-header {
  background: #dddbdb;
  height: 70px;
  overflow: hidden;

  .chat-title {
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    color: #201f1f;
    padding: 5px 5px 10px 5px;
    width: 80%;
    margin: auto;
    overflow: hidden;
  }
  .back-btn {
    position: absolute;
    left: 10px;
    margin-top: 7px;
    cursor: pointer;
  }
  .close-btn {
    position: absolute;
    right: 10px !important;
    top: 5px !important;
    margin-left: 98%;
    cursor: pointer;
  }
}

.chat-box {
  // min-height: 400px;
  // height: 100%;
  // bottom: 0px;
  // width: 100%;
  padding: 0px;

  .chat-body {
    padding: 10px;
    height: 78%;
    overflow-y: auto !important;
    overflow-x: hidden;
  }

  .chat-item {
    .message {
      border-radius: 10px;
      padding: 10px;
    }
    .mymessage {
      background: rgba(162, 195, 255, 0.719) !important;
    }
    .avatar {
      font-size: 30px;
      text-align: center !important;
    }
    .avatar.me {
      color: rgba(162, 195, 255, 0.719) !important;
      margin-left: -15px;
    }
  }

  .chat-type {
    font-size: 10px;
  }
  .chat-time {
    font-size: 8px;
  }
  .back-btn {
    font-size: 20px;
    margin-left: 5px;
  }
  // .chat-item.Admin .message {
  //   background: rgba(162, 195, 255, 0.719) !important;
  // }
  .chat-item.Owner .avatar svg {
    color: rgba(252, 166, 116, 0.137);
  }
  .chat-item.Owner .message {
    background: rgba(252, 166, 116, 0.137);
  }

  .chat-item.Admin .message {
    background: rgba(211, 134, 134, 0.24);
  }
  .chat-item.Admin .avatar svg {
    color: rgba(211, 134, 134, 0.24);
  }

  .chat-item .message {
    background: rgba(238, 238, 238, 0.151);
  }
  .chat-item .avatar svg {
    color: #eee;
  }

  .chat-footer {
    margin-left: 0px;
    position: absolute;
    height: 50px;
    bottom: 0px;
    width: 100%;
    position: absolute !important;
    margin: 0px !important;
    display: block;
    button {
      height: 48px;
      width: 100%;
      border: 1px solid #dfd7d7;
    }
    textarea {
      width: 100%;
      border: 1px solid #dfd7d7;
    }
    .left {
      float: left;
      display: block;
      width: 50px !important;
    }
    .right {
      float: right;
      display: block;
      width: 248px !important;
      padding-left: 5px;
      textarea {
        width: 100%;
      }
    }
  }

  .send-btn {
    background: #d8d4d4;
    border: 1px solid #33333357;
    border-radius: 5px !important;
  }
}

.start-chatting {
  font-size: 20px;
  padding-top: 120px;
  text-align: center;
}

.vehicle-page-chat.chat-box {
  min-height: 350px;
  max-height: 350px;

  .chat-body {
    padding: 10px;
    height: 300px !important;
    overflow-y: scroll;
  }

  border: 1px solid #dfd7d7;
  border-radius: 5px;
  background: #eee3;
  overflow: hidden;

  .chat-footer {
    width: 99%;
    margin-left: 2px;
    margin-bottom: -30px !important;
  }
}

.open-btn-message {
  border: none !important;
}
.chat-list-box.close button {
  width: 100%;
}
.chat-list-box.close {
  background: #0dadc6;
  height: 60px;
  border-radius: 30px 30px 0px 0px;
}
.chat-list-box.close .closed {
  display: block;
}
.chat-list-box.close .opened {
  display: none;
}

.chat-list-box.open .closed {
  display: none;
}
.chat-list-box.open .opened {
  display: block;
}

.chat-item {
  cursor: pointer;
}
.chat-item.not-seen {
  font-weight: bold;
}

.chat-input * {
  margin: 0px;
}
.chat-input button {
  margin-left: -10px;
  width: 100%;
}

.chat-input textarea {
  width: 100%;
}

.chat-item {
  padding: 10px;
  margin: 10pxpx;
}

.chat-item.chat-box-item {
  background: none !important;
}

.chat-list-box .chat-item:hover {
  background: none;
}

.chat-list-box .chat-item {
  background: #eee;
}

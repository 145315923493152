@media only screen and (min-width: 992px) and (max-width: 1199px) {

	.footerDetails {
    	padding: 0 25px !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.footerDetails {
    	padding: 0 25px !important;
	}

}

@media (max-width: 767px) {
	.footerContainer {
		padding-top: 50px !important;
	}
	.footerDetails {
		display: table !important;
		padding-left: 25px !important;
		padding-right: 25px !important;
	}
	.footerDetails .logoBox {
		display: table;
		width: 100%;
		text-align: center;
		margin-bottom: 30px;
	}
	.footerDetails > div {
		margin-right: 0;
	}
	.footerDetails .destinationBox {
		float: left;
		width: 50%;
	}
	.footerDetails .followBox {
		display: table;
		width: auto;
		padding-top: 30px;
	}
	.footerDetails .supportBox {
		padding-left: 30px;
	}
	.footerDetails .followBox {
		display: table;
		width: 100%;
		padding-top: 30px;
	}
	.socialMediaContainer {
		width: 45%;
		margin: 10px 0;
	}
}

@media (max-width: 480px) {

}
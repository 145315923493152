.field-line {
  margin-top: 10px;
  input,
  select {
    border: 1px solid #333 !important;
  }
}

::placeholder {
  color: #7d6c6c;
  opacity: 0.4;
}

.required {
  color: red;
}

.error-box {
  background: #e0b4b4 !important;
  padding: 20px;
  color: #333;
  border-radius: 5px;
  margin-bottom: 10px;
}

input.error {
  //background-color: #c91d1dc0 !important;
  color: rgb(211, 180, 180) !important;
}
select.error {
  //background-color: #c91d1dc0 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  color: rgb(211, 180, 180) !important;
  box-shadow: none;
}

.birthdaycomponent {
  margin-left: 2px !important;
  width: 180px;
  background: #fff;
  border: 1px solid #333;

  input,
  select {
    border: none !important;
  }
}

.birthdaycomponent select {
  border: 0px;
  outline: none;
  background: none;
}

.birthday-error .birthdaycomponent {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
}

.bank-note-update {
  color: red;
}

.alert-info,
.bank-note {
  background: #d7e9e9dd;
  color: #393131;
  padding: 10px;
  font-size: 18px;
  border-radius: 10px;
}

.bank-note {
  font-size: 14px;
}

.subtitle {
  margin-top: 20px;
  font-size: 20px;
}

.personal-details-container,
.owner-details-container {
  padding: 20px 20px 30px 30px;
  margin-bottom: 20px;
  background: #eee;
  width: 100%;
}

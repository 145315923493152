.productListContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5%;
}

.productsPageDescriptionBox {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 5%;
  justify-content: center;
  align-items: center;
  width: 55%;
  flex-direction: column;
}

.productsPageTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;
  margin-bottom: 3%;
}

.productsPageSecondary {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 2%;
}

.productsContainer {
  display: flex;
  position: relative;
  margin-top: 2%;
  margin-bottom: 3%;
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;
}

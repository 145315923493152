.create-vehicle-container.section-0 {
  .section-0 {
    display: block !important;
  }
}
.create-vehicle-container.section-1 {
  .section-1 {
    display: block !important;
  }
}
.create-vehicle-container.section-2 {
  .section-2 {
    display: block !important;
  }
}
.create-vehicle-container.section-3 {
  .section-3 {
    display: block !important;
  }
}
.create-vehicle-container.section-4 {
  .section-4 {
    display: block !important;
  }
}
.create-vehicle-container.section-5 {
  .section-5 {
    display: block !important;
  }
}
.create-vehicle-container.section-6 {
  .section-6 {
    display: block !important;
  }
}

.create-vehicle-container {
  .addon-amenities {
    width: 600px !important;
    .cost {
      width: 100px !important;
    }
  }

  .primary-box {
    font-size: 10px;
    position: absolute;
    bottom: -10px;
    left: 5px;
  }

  .subcontent {
    display: none;
    clear: both;
  }
  .tab-container {
    width: 100%;
    margin-bottom: 30px;

    .tab-item.active {
      background: #c8c6c6;
      border-radius: 10px;
      cursor: default;
    }
    .tab-item.error {
      border: 1px solid red !important;
      border-radius: 10px;
      cursor: default;
    }
    .tab-item {
      cursor: pointer;
      font-weight: bold;
      text-align: center;
      padding: 10px;
      width: 200px;
      display: block;
      float: left;
    }
  }

  .clearfix {
    clear: both;
  }

  .error-message {
    color: red;
  }

  .loader {
    display: block;
    left: 50%;
    margin-left: -40px;
    top: 50%;
    margin-top: -40px;
    position: fixed;
    width: 80px;
    height: 80px;
    z-index: 7777;

    .lds-dual-ring {
      display: inline-block;
      width: 80px;
      height: 80px;
    }
    .lds-dual-ring:after {
      content: ' ';
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .overlay {
    background: #0000004d;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    z-index: 55;
  }

  .DayPicker-Months {
    width: 780px;
  }

  .Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }

  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }

  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  background: #fff;
  padding: 20px;

  .thumbnails {
    border-spacing: 20px;
    border-collapse: separate;
    .thumbnail-item:hover {
      filter: none;
    }
    .thumbnail-item {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 10px;
      background: #eee;
      display: table-cell;
      width: 80px;
      height: 100px;
      position: relative;
      margin-left: 20px;
      vertical-align: middle;
      text-align: center;
      img {
        cursor: pointer;
      }
      img:hover {
        border: 1px solid #c0c0c0;
      }
      .delete-thumb:hover {
        color: red;
        opacity: 1;
      }
      .delete-thumb {
        opacity: 0.5;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
  }
  .upload-button {
    margin-left: 20px;
  }

  .add-vehicle-btn {
    float: right;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  table td {
    padding: 0px;
  }
  .delete-btn {
    cursor: pointer;
  }
  .delete-btn:hover {
    color: red;
  }
  .date-td {
    width: 150px;
    padding-left: 10px;
  }
  .date-td.title {
    padding-left: 64px;
  }

  .line-item {
    .rule-rates {
      border: 1px solid #ccc;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 20px;
      width: 900px;
      padding: 20px 20px 0px 20px;
      li {
        display: block;
        height: 30px;
      }
    }

    .calculator-box {
      padding: 20px 20px 70px 20px;
      border-radius: 20px;
      width: 900px;
      border: 1px solid #ccc;

      table {
        table-layout: fixed;
        width: 850px !important;

        td {
          padding: 10px;
        }
        td.td-rate {
          width: 100px !important;
        }

        thead td {
          background: #eee;
          padding: 10px;
        }
        tfoot tr {
          border-top: 1px solid #33333321;
        }
        tfoot td {
          font-weight: bold;
        }
      }

      button {
        float: right;
      }
    }

    .datepicker {
      width: 150px !important;
    }
    margin: 30px;
    b {
      display: inline-block;
      width: 150px;
    }
    select,
    input[type='text'] {
      width: 500px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #8a8a8a;
    }

    input[type='file'] {
      margin-bottom: 10px;
      width: 500px;
      padding: 10px;
      border: none !important;
    }
    textarea {
      width: 500px;
      height: 100px;
      border: 1px solid #8a8a8a;
      border-radius: 5px;
    }

    select,
    input,
    textarea {
      margin-bottom: 10px;
    }

    ul,
    li {
      padding: 0px !important;
      list-style: none;
    }

    .date,
    .amount,
    .number,
    .latlong {
      padding: 10px;
      border: 1px solid #8a8a8a;
      border-radius: 5px;
      width: 80px !important;
      margin-right: 10px;
    }

    .latlong {
      width: 150px !important;
    }
    .date-applied {
      width: 200px !important ;
    }

    .thumbnails {
      img {
        width: 50px;
        margin-right: 10px;
      }
    }

    .error.thumbnails {
      max-width: 120px;
      border-radius: 5px;
      padding: 10px 10px 5px 10px;
    }

    .upload-button {
      cursor: pointer;
    }
    .error {
      border: 1px solid red !important;
      outline: 1px solid red !important;
    }
    .rounded-error li {
      border: 1px solid red !important;
      border-radius: 5px;
    }
    .included-amenities {
      li {
        display: block;
        max-width: 250px;
        padding: 10px 10px 0px 20px !important;
        margin-bottom: 10px;
      }
    }
  }
}

.AboutUs {
  font-family: 'Poppins', sans-serif;
}

.aboutUsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-bottom: 10%;
  background: #f7f7f7;
}

.aboutUsTitleBox {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 5%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aboutUsTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;
}

.aboutUsTitle2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
  margin-top: 2%;
}

.aboutUsBody {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 70%;
  padding-bottom: 6%;
}

.aboutUsImage {
  width: 45%;
}

.aboutUsBodyText {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.aboutUsBodyTexBox {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.aboutUsBodyText1 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 5%;
}

.aboutUsBodyText2,
.aboutUsBodyText3 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.aboutUsBodyText3 {
  margin-left: 10%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.contactUsIconBox {
    	width: 100% !important;
    	display: table !important;
    	margin: auto;
	}
	.contactUsIconWrapper:not(:first-child) {
    	margin: 0 auto 30px;
    	float: left;
    	width: 50%;
	}
	.contactUsBody {
    	width: 100% !important;
    	padding: 0 25px;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.contactUsIconBox {
    	width: 100% !important;
    	display: table !important;
    	margin: auto;
	}
	.contactUsIconWrapper:not(:first-child) {
    	margin: 0 auto 30px;
    	float: left;
    	width: 50%;
	}
	.contactUsBody {
    	width: 100% !important;
    	padding: 0 25px;
	}

}

@media (max-width: 767px) {

	.contactUsIconBox {
    	width: 100% !important;
    	display: table !important;
    	margin: 0 auto !important;
	}
	.contactUsIconWrapper {
    	margin: 0 auto 30px;
    	float: none;
    	width: 100%;
	}
	.contactUsBody {
		display: table !important;
    	width: 100% !important;
    	padding: 0 25px;
	}
	img.contactUsImage, .contactUsBodyFormContainer {
    	width: 100% !important;
	}
	.contactUsBodyFormContainer {
    	margin-top: 20px;
	}
	.contactUsIcon {
    	width: auto !important;
	}
	.contactUsIconWrapper:first-child {
    	margin-bottom: 0 !important;
	}

}

@media (max-width: 480px) {

	.contactUsForm {
    	margin-top: 15px;
	}
	.contactUsForm, .contactUsFormRow {
    	display: table !important;
	}
	.contactUsForm, .contactUsFormInputBox {
    	width: 100% !important;
    	margin-bottom: 10px;
	}
	button.contactUsSendButton {
    	width: 100%;
	}

}
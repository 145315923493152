.loginFormContainer input::placeholder {
    color: #333 !important;
    opacity: 1;
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
	.loginContainer {
		padding: 0 50px;
	}
	.loginImageIcon, .loginFormContainer {
		width: 50% !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.loginContainer {
		padding: 0 50px;
	}
	.loginImageIcon, .loginFormContainer {
		width: 50% !important;
	}
	.pageBannerContainer {
    	margin-top: 25px !important;
	}
	.loginSubmitButton {
    	width: 90% !important;
	}

}

@media (max-width: 767px) {

	.loginContainer {
		padding: 0 25px;
	}
	.loginImageIcon, .loginFormContainer {
		width: 100% !important;
	}
	.loginSubmitButton {
    	width: 90% !important;
	}
	.imageFormContainer {
    	display: table !important;
	}
	.loginImageIcon {
		width: 40% !important;
		display: table;
		margin: 0 auto 30px !important;
	}

}

@media (max-width: 480px) {


	.loginAccountTitle {
    	font-size: 25px;
	}
	.loginContainer {
		padding: 0 15px;
	}
	.imageFormContainer {
		display: table !important;
		margin-top: 0 !important;
	}

}
.App {
  height: 113vh;
  width: 100%;
}

.circle {
  background-color: white;
  border-radius: 16px;
  color: black;
  height: 2.5em;
  position: relative;
  width: 8em;
  border: 1px solid transparent;
  font-weight: 800;
  font-size: small;
}

.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.hover {
  background-color: #0078d4;
}

.confirmBookingContainer {
  .payment-notes {
    margin-bottom: 10px;
    background: #eee;
    padding: 10px 10px 10px 0px;
  }

  input[type='text'],
  input[type='number'],
  select,
  textarea {
    border: 1px solid #333;
    // width: 350px;
    width: 100%;
    text-align: left;
  }

  textarea {
    height: 200px;
  }

  option:disabled {
    background: #dddddd !important;
  }

  input.error,
  select.error,
  .div-error {
    background: rgb(245, 219, 219) !important;
  }

  span.required {
    color: red;
  }
  .input-heading {
    font-weight: bold;
    margin-top: 15px;
  }

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-bottom: 10%;
  background: #f7f7f7;
}

.guest-number {
  text-align: center;
}

.payButtonHalfSized.disabled {
  background-color: #eee;
}
.payButtonHalfSized {
  width: 47%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #ff8c3f;
  border-radius: 3px;
  border: none;
  padding: 3%;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  text-align: center;
  color: #ffffff;
}

.vehicleDetailsButtonFullSized {
  @extend .payButtonHalfSized;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

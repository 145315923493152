.membershipContainer {
  background-image: url('//cdn.cnchire.com.au/1.png');
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: -13%;
  background-position-y: 50%;
  display: block;
  position: relative;
  justify-content: center;
  align-items: center;
  /* padding: 8%; */
  padding-top: 8%;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 4%;
  z-index: 0;
}

.lowerbadgeContainer {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 60px;
}

.membershipTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 127.5%;
  text-align: center;
  color: #384a5e;

  display: block;
  position: relative;
  margin-bottom: 2%;
}

.membershipDesc {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  opacity: 0.6;

  display: block;
  position: relative;
}

.membershipBenefits {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.benefit {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 20%;
  height: 5%;
  margin: 3%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  justify-content: center;
  align-items: center;
}

.benefitText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
}

.benefitIcon {
  width: 50%;
  height: 50%;
  margin-bottom: 5%;
}

.faqListContainer {
  width: 1200px;
  margin: auto;

  .searchbox input {
    padding: 10px 10px 10px 30px;
    width: 634px;
    height: 55px;
    box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.2);
    border-radius: 52px;
    border: none;

    margin-bottom: 100px;
    margin-left: 250px;

    background: url(//cdn.cnchire.com.au/imagesfaqs/search-icon.svg) no-repeat
      top left #fff;
    background-position: 23px 20px;
    padding-left: 55px;
  }

  .item {
    cursor: pointer;
    font-family: roboto;
    color: #00000080;
    font-size: 12px;
    padding: 10px;
    margin: 10px;
    b {
      font-weight: normal;
      font-size: 16px;
      background: url(//cdn.cnchire.com.au/imagesfaqs/faq-icon.svg) no-repeat
        top left;
      padding-right: 30px;
      display: block;
      padding-left: 43px;
      span {
        margin-left: 10px;
        display: none;
      }
    }

    b:hover span {
      display: inline-block;
    }

    background: url(//cdn.cnchire.com.au/imagesfaqs/faq-arrow.svg) no-repeat top
      right #f5f5f5;
    background-position: 99% 12px;
  }

  .item:hover {
    border: 1px solid #3333334d;
  }
  .addBtn {
    margin-bottom: 80px;
  }
  .addBtn svg {
    font-size: 70px;
    float: right;
    cursor: pointer;
  }
  .addBtn:hover svg {
    color: blue;
  }

  .deleteBtn:hover svg {
    color: red;
  }
  .deleteBtn svg {
    color: #333;
    font-size: 12px;
  }

  .editBtn:hover svg {
    color: blue;
  }
  .editBtn svg {
    color: #333;
    font-size: 12px;
  }
}

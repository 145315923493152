.location-searches {
  margin: 10px 15px 50px 15px;
  padding: 20px 0px 20px 30px;

  position: relative;
  overflow: hidden;
  width: 97.6%;
  //height: 217px;
  min-height: 117px;

  background: #f7f7f7;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #2f3073;
    height: 38px;
    background-image: url(//cdn.cnchire.com.au/dashboard/heading-line.svg);
    background-repeat: no-repeat;
    background-position: 0px bottom;
  }

  .more-searches {
    width: 30px;
    position: absolute;
    z-index: 33443;
    text-align: right;
    font-size: 30px;
    top: 100px;
    right: 30px;
    opacity: 0.1;
  }

  .more-searches:hover {
    cursor: pointer;
    opacity: 1;
  }

  .location-list {
    padding-left: 5px;
    padding-top: 9px;
    margin-top: 30px;
    overflow: hidden;
    height: 80px;

    .location-item:nth-child(1) {
      .circle {
        background-color: #536cfb;
      }
      .name {
        color: #536cfb;
      }
    }

    .location-item:nth-child(2) {
      .circle {
        background-color: #f28b31;
      }
      .name {
        color: #f28b31;
      }
    }

    .location-item:nth-child(3) {
      .circle {
        background-color: #bbbd4b;
      }
      .name {
        color: #bbbd4b;
      }
    }

    .location-item:nth-child(4) {
      .circle {
        background-color: #419788;
      }
      .name {
        color: #419788;
      }
    }

    .location-item:nth-child(5) {
      .circle {
        background-color: #7cb342;
      }
      .name {
        color: #7cb342;
      }
    }

    .location-item:nth-child(6) {
      .circle {
        background-color: #ff2758;
      }
      .name {
        color: #ff2758;
      }
    }

    .location-item:nth-child(7) {
      .circle {
        background-color: #f28b31;
      }
      .name {
        color: #f28b31;
      }
    }

    .location-item:nth-child(8) {
      .circle {
        background-color: #536cfb;
      }
      .name {
        color: #536cfb;
      }
    }

    .location-item {
      position: relative;
      min-width: 90px;
      height: 100px;
      display: block;
      float: left;

      .name {
        margin-left: 60px;
        display: block;
        padding: 10px 20px;
        font-size: 16px;
      }

      .count {
        font-size: 14px;
        color: #000;
      }

      .circle {
        background-image: url(//cdn.cnchire.com.au/dashboard/location-marker.svg);
        width: 63px;
        height: 63px;
        border-radius: 50%;
        margin-right: 40px;
        background-repeat: no-repeat;
        background-position: center center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
        position: absolute;
      }
    }
  }
}

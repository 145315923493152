.vehicles-container {
  background: #fff;
  padding: 20px;

  .btn-approve {
    float: right;
  }

  .line-item {
    img {
      width: 150px;
      margin: 2px;
    }
  }

  h4 {
    margin-top: 40px;
  }
}

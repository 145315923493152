.pageBannerContainer {
  background-image: url('//cdn.cnchire.com.au/HeaderBanner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 320px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  z-index: 1;
}

.pg-banner {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: 15%;
  margin-top: 7%;
  margin-bottom: 3%;
}

.pg-banner-title {
  display: flex;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 127.5%;
  color: #ffffff;
  transform: rotate(-0.12deg);
}

.pg-banner-description {
  display: flex;
  position: relative;
  flex-direction: row;
}

.pg-banner-linksBanner {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #ffffff;
  transform: rotate(-0.12deg);
  padding-right: 2%;
}

a.pg-banner-linksBanner:hover {
  color: #ffffff;
}

.pg-banner-linksBanner-title {
  @extend .pg-banner-linksBanner;
  color: #ff8c3f;
}

.advanceSearchBox {
  justify-content: center;
  align-items: center;
  margin: auto;
  width: auto;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 4px 4px 56px rgba(255, 140, 63, 0.2);
  border-radius: 15px;
  z-index: 1;
}

.advanceSearchBoxInVehiclePage {
  @extend .advanceSearchBox;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.topRectangle {
  display: flex;
  margin: auto;
  margin-top: -1.5%;
  width: 10%;
  height: 20%;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  border-radius: 69px;

  /* Pagination dots container */
  display: none;
}

.advanceSearchBoxForm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.advanceSearchContainer {
  display: flex;
  flex-direction: column;
  margin: 1%;
  width: 25%;
  height: 50%;
  /* margin-bottom: 5%; */

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
  .dropdown-toggle::before {
    display: none !important;
  }
  .dropdown-toggle::after {
    color: rgb(202, 198, 198) !important;
  }

  .btn-primary {
    border: none !important;
    i.caret:before {
      display: none;
    }
  }
}

.locationSearchContainer {
  @extend .advanceSearchContainer;
  width: 35%;
  margin-left: 0;
}

.advanceSearchInputBox {
  height: 70%;
  background: #f7f7f7;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  border: none;
  margin-top: 5%;
  padding: 5%;
}

.vehicleTypeDropdown {
  margin-top: 10px;
  padding: 5%;
  background: #f7f7f7 !important;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  border: none;
  width: 100%;
  height: 45px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: rgba(0, 0, 0, 0.3);
  text-align: left;
}

.dropdown-toggle:active {
  background: #f7f7f7;
  background-color: #f7f7f7;
}

.show > .btn-primary.dropdown-toggle {
  background: #f7f7f7;
  background-color: #f7f7f7;
}

.btn-primary:focus {
  background: #f7f7f7;
  background-color: #f7f7f7;
}

.btn-primary:hover {
  background: #f7f7f7;
  background-color: #f7f7f7;
}

.btn-primary:active {
  background: #f7f7f7;
  background-color: #f7f7f7;
}

.typeText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: rgba(0, 0, 0, 0.3);
  text-align: left;
  width: 70%;
}

.typeTextValue {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
  text-align: left;
  width: 70%;
}

.searchButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 5%;
  height: 45px;
  background: #ff8c3f;
  border-radius: 3px;
  /* margin: 2%; */
  margin-top: 5%;
  margin-bottom: 1.9%;
  margin-left: 2%;
  padding: 1.5%;
  border: none;
}

.searchIcon {
  width: 90%;
}

.advanceSearchButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 45px;
  background: #ff8c3f;
  border-radius: 3px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  /* or 20px */

  color: #ffffff;
  /* margin: 2%;  */
  margin-top: 5%;
  margin-bottom: 1.9%;
  margin-left: 1%;
  padding: 0, 50px;
  border: none;
}

.DateRangePickerInput {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DateInput_input {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  // line-height: 15px;
  // padding-top: 5%;
  // padding-bottom: 5%;
  // height: 100%;
  background: #f7f7f7;
}

.DateRangePickerInput_arrow_svg {
  width: 15px;
  height: 15px;
}

.DateRangePicker {
  height: 70%;
  background: #f7f7f7;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  border: none;
  margin-top: 5%;
}

.DateRangePickerInput__withBorder {
  border: none;
  background: #f7f7f7;
}

.locationInputBox {
  @extend .vehicleTypeDropdown;
  @extend .typeTextValue;
  width: 100%;
}

.css-2b097c-container {
  margin-top: 10px;
  width: 100%;
}

.css-yk16xz-control {
  // @extend .vehicleTypeDropdown;
  @extend .typeTextValue;
  width: 100%;
  background: #f7f7f7 !important;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  border: none !important;
  width: 100%;
  height: 45px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: rgba(0, 0, 0, 0.3);
  text-align: left;
  // padding-top: 4.5%;
  // padding-bottom: 4.5%;
}

.css-1pahdxg-control {
  // padding-top: 4.5%;
  // padding-bottom: 4.5%;
  width: 100%;
  height: 45px;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.dropdown-menu.show {
  // width: 100%;
}

.navDropdownItem {
  width: 100%;
}

.advanceSearchBoxContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.advanceSearchBoxContainerForm {
  @extend .advanceSearchBoxContainer;

  .dropdown-toggle {
    background: #fff !important;
    border: #eee 1px solid;
  }
}

.dropdown-toggle::after,
.locationSearchContainer .dropdown-toggle::after {
  color: #fff0 !important;
}

.vehicleTypeDropdown.btn-primary {
  text-align: left !important;
}

.advanceSearchBox {
  top: 60%;
}

.searchInput {
  margin-top: 10px;
  padding: 10px;
  border: none !important;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
}

.locationCompleter {
  cursor: pointer;
  border-top: 1px solid #ccc;
  margin-top: 70px;
  position: absolute;
  padding: 10px;
  width: 465px;
  background: #fcfcfc;
  overflow: hidden;
}

.locationCompleter:hover {
  background: #ffffce;
}

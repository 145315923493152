@media only screen and (min-width: 992px) and (max-width: 1199px) {
   .dashboard-container-layout .sidebar ul {
		padding: 0;
	}
	.dashboard-container-layout {
		background-size: unset !important;
		background-position: unset !important;
	}
	.dashboard-container .date-filter {
		margin: 0;
		width: 100%;
		height: auto;
		padding-right: 20px;
		padding-left: 20px;
	}
	.dashboard-container-layout .top-container .col-8 {
		max-width: 60%; flex: none; order: 2; display: flex;
	}
	.dashboard-container-layout .top-container .more {
		order: 2; margin-left: auto;
	}
	.dashboard-container-layout .top-container .search-input {
		order: 1;
	}
	.dashboard-container-layout .top-container .col-4 {
		max-width: 40%; flex: none; order: 1;
	}
	.dashboard-container .date-filter .DayPickerInput, .dashboard-container .date-filter .DayPickerInput input {
		width: 100%;
	}
	.dashboard-container .date-filter .load-btn.btn {
		margin: auto !important;
		position: relative;
		top: 0 !important;
		margin-top: 20px !important;
	}
	.dashboard-container .location-searches {
		margin: 0 !important;
		width: 100%;
		padding: 20px !important;
	}
	.dashboard-container .boxes {
		padding-left: 20px;
	}
	.dashboard-container .sales-stats {
		margin: 10px 0 0;
	}
	.dashboard-container .sales-stats, .dashboard-container .latest-booking {
		margin: 10px 0 0;
	}
	.dashboard-container .latest-booking {
		padding: 20px;
	}
	.dashboard-container .latest-booking .list-body {
		padding: 15px 0 !important;
		background-color: transparent !important;
	}
	.dashboard-container .latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail .vehicle-name {
		width: 100% !important;
		padding-left: 0 !important;
		height: auto !important;
	}
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail {
		margin-top: 15px;
	}
	.dashboard-container-layout .sidebar ul li span {
		padding-left: 0 !important;
		margin-right: 0 !important;
		margin-left: 10px;
	}
	.dashboard-container-layout .sidebar {
		position: fixed;
		z-index: 1;
		height: 100vh;
		max-width: 300px;
		display: none;
	}
	.dashboard-container-layout .sidebar.less {
		display: block !important;
        z-index: 99999;
	}
	.dashboard-container-layout .main {
		max-width: 100%;
		flex: none;
	}
}

@media (min-width: 768px) and (max-width: 991px) {

	.dashboard-container-layout .sidebar ul {
		padding: 0;
	}
	.dashboard-container-layout {
		background-size: unset !important;
		background-position: unset !important;
	}
	.dashboard-container .date-filter {
		margin: 0;
		width: 100%;
		height: auto;
		padding-right: 20px;
		padding-left: 20px;
	}
	.dashboard-container-layout .top-container .col-8 {
		max-width: 60%; flex: none; order: 2; display: flex;
	}
	.dashboard-container-layout .top-container .more {
		order: 2; margin-left: auto;
	}
	.dashboard-container-layout .top-container .search-input {
		order: 1;
	}
	.dashboard-container-layout .top-container .col-4 {
		max-width: 40%; flex: none; order: 1; padding-left: 30px;
	}
	.dashboard-container .date-filter .DayPickerInput, .dashboard-container .date-filter .DayPickerInput input {
		width: 100%;
	}
	.dashboard-container .date-filter .load-btn.btn {
		margin: auto !important;
		position: relative;
		top: 0 !important;
		margin-top: 20px !important;
	}
	.dashboard-container .location-searches {
		margin: 0 !important;
		width: 100%;
		padding: 20px !important;
	}
	.dashboard-container .boxes {
		padding-left: 20px;
	}
	.dashboard-container .sales-stats {
		margin: 10px 0 0;
	}
	.dashboard-container .sales-stats, .dashboard-container .latest-booking {
		margin: 10px 0 0;
	}
	.dashboard-container .latest-booking {
		padding: 20px;
	}
	.dashboard-container .latest-booking .list-body {
		padding: 15px 0 !important;
		background-color: transparent !important;
	}
	.dashboard-container .latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail .vehicle-name {
		width: 100% !important;
		padding-left: 0 !important;
		height: auto !important;
	}
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail {
		margin-top: 15px;
	}
	.dashboard-container-layout .sidebar ul li span {
		padding-left: 0 !important;
		margin-right: 0 !important;
		margin-left: 10px;
	}
	.dashboard-container-layout .sidebar {
		position: fixed;
		z-index: 1;
		height: 100vh;
		max-width: 300px;
		display: none;
	}
	.dashboard-container-layout .sidebar.less {
		display: block !important;
		z-index: 99999;
	}
	.dashboard-container-layout .main {
		max-width: 100%;
		flex: none;
	}
	.dashboard-container-layout .top-container {
		height: auto !important;
	}
	.boxes .box-item .box-caption {
		bottom: 0 !important;
		transform: translate(-50%, 0);
		left: 50%;
		width: 100%;
		text-align: center;
		padding: 15px 0;
	}

}

@media (max-width: 767px) {
    .dashboard-container-layout .top-container .col-8 {
        z-index: 1;
    }
    .dashboard-container-layout .sidebar.less {
      display: block !important;
      z-index: 99999;
    }
	.dashboard-container-layout .sidebar ul {
		padding: 0;
	}
	.dashboard-container-layout {
		background-size: unset !important;
		background-position: unset !important;
	}
	.dashboard-container .date-filter {
		margin: 0;
		width: 100%;
		height: auto;
		padding-right: 20px;
		padding-left: 20px;
	}
	.dashboard-container-layout .top-container .col-8 {
		max-width: 60%; flex: none; order: 2; display: flex; margin-top: -50px;
	}
	.dashboard-container-layout .top-container .more {
		order: 2; margin-left: auto; margin-right: 0 !important;
	}
	.dashboard-container-layout .top-container .search-input {
		order: 1;
	}
	.dashboard-container-layout .top-container .col-4 {
		max-width: 40%; flex: none; order: 1; padding-left: 30px;
	}
	.dashboard-container .date-filter .DayPickerInput, .dashboard-container .date-filter .DayPickerInput input {
		width: 100%;
	}
	.dashboard-container .date-filter .load-btn.btn {
		margin: auto !important;
		position: relative;
		top: 0 !important;
		margin-top: 20px !important;
		width: 100%;
		right: 0 !important;
	}
	.dashboard-container .location-searches {
		margin: 0 !important;
		width: 100%;
		padding: 20px !important;
	}
	.dashboard-container .boxes {
		padding-left: 20px;
		margin-top: 20px;
		width: 100%;
	}
	.dashboard-container .sales-stats {
		margin: 10px 0 0;
	}
	.dashboard-container .sales-stats, .dashboard-container .latest-booking {
		margin: 10px 0 0;
		width: 100%;
		padding-right: 30px;
	}
	.dashboard-container .latest-booking {
		padding: 20px;
	}
	.dashboard-container .latest-booking .list-body {
		padding: 15px 0 !important;
		background-color: transparent !important;
	}
	.dashboard-container .latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail .vehicle-name {
		width: 100% !important;
		padding-left: 0 !important;
		height: auto !important;
	}
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail {
		margin-top: 15px;
	}
	.dashboard-container-layout .sidebar ul li span {
		padding-left: 0 !important;
		margin-right: 0 !important;
		margin-left: 10px;
	}
	.dashboard-container-layout .sidebar {
		position: fixed;
		z-index: 1;
		height: 100vh;
		max-width: 300px;
		display: none;
	}
	.dashboard-container-layout .sidebar.less {
		display: block !important;
		z-index: 99999;
	}
	.dashboard-container-layout .main {
		max-width: 100%;
		flex: none;
	}
	.dashboard-container-layout .top-container {
		height: auto !important;
		padding: 0 25px 25px;
	}
	.boxes .box-item .box-caption {
		bottom: 0 !important;
		transform: translate(-50%, 0);
		left: 50%;
		width: 100%;
		text-align: center;
		padding: 15px;
	}
	.dashboard-container-layout .top-container .col-8, 
	.dashboard-container-layout .top-container .col-4 {
		max-width: 100%;
	}
	.dashboard-container-layout .top-container .col-4 {
		padding-right: 30px;
	}
	.dashboard-container .boxes .col-4 {
		max-width: 50%;
		flex: none;
	}
	.dashboard-container .boxes .box-item {
		background-size: 22%;
		background-position: 50% 55%;
	}
	.latest-booking .list-outer-box {
		padding-right: 0 !important;
	}
	.latest-booking .list-body .row > div {
		display: flex;
		flex: none;
		max-width: 100%;
		margin: 5px 0;
	}
	.latest-booking .list-outer-box .list-body .row div:nth-child(5) {
    	padding-left: 0;
	}
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container .thumbnail, 
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container .detail {
		float: none !important;
	}
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container,
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container .thumbnail {
		margin: auto;
		text-align: center;
	}
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container .thumbnail {
		background-size: cover !important;
		width: 100% !important;
		height: 200px !important;
		box-shadow: none !important;
	}
	.latest-booking .list-outer-box .list-body .row {
		background-color: #fff;
		margin: 20px 0;
		padding: 30px 20px;
		box-shadow: 4px 4px 16px rgba(0,0,0,.15);
	}
	.latest-booking .list-outer-box .list-head {
		display: none !important;
	}
	.latest-booking .description {
		margin-bottom: 0 !important;
	}
	.latest-booking .list-outer-box .list-body .row div:nth-child(4), 
	.latest-booking .list-outer-box .list-body .row div:nth-child(5) {
		float: left;
		width: auto;
	}
	.latest-booking .list-body .row .btn {
		width: 100%;
		padding: 12px 15px;
	}
	.dashboard-container-layout .search-input {
		background-image: none !important;
	}
	.sales-stats .chart-legend {
		margin-left: 0 !important;
	}
	.sales-stats .chart-legend {
		background-size: 70% !important;  position: relative !important;
	}
	.sales-stats .chart-legend .revenue-legend {
		left: 58px !important;
	}
	.sales-stats .chart-legend .commision-legend {
		right: 98px !important;
	}

}

@media (max-width: 480px) {

	.dashboard-container .boxes .col-4 {
		max-width: 100%;
	}
	.latest-booking .list-body .row > div {
		padding: 0;
	}
	.latest-booking .list-outer-box .list-body .vehicle-thumb-container .thumbnail {
		height: 150px !important;
	}
	.latest-booking .list-outer-box .list-body .row div:nth-child(5) {
		padding-left: 10px;
	}
	.latest-booking .list-outer-box .list-body .user-detail {
		padding-left: 10px;
	}
	.latest-booking .list-outer-box .list-body .avatar {
		width: 35px !important;
		height: 35px !important;
		background-size: contain;
	}
	.dashboard-container-layout .top-container .search-input {
		width: 228px !important;
	}
	.dashboard-container .date-filter .col-6 {
		max-width: 100%;
		flex: none;
	}
	.dashboard-container .date-filter .col-6:nth-child(2) {
		color: #333;
	}
	.boxes .box-item .box-value {
		transform: translate(-50%, 0);
		left: 50%;
		right: unset !important;
	}
	.dashboard-container .boxes .box-item {
		background-size: 20%;
		background-position: 50% 65%;
	}
	.sales-stats .chart-legend {
		background-size: 52% !important; margin-top: 0 !important; position: relative !important;
	}
	.sales-stats .chart-legend .revenue-legend {
		left: 43px !important;
		font-size: 12px !important;
	}
	.sales-stats .chart-legend .commision-legend {
		right: 165px !important;
		font-size: 12px !important;
	}
	.chat-list-box {
    	width: 100% !important;
	}
	body.dashboard-main-page {
    	overflow-x: hidden;
	}

}
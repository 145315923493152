.date-filter {
  margin-left: 50px;
  color: #fff;
  width: 870px;
  height: 160px;
  position: relative;

  .invalid-date {
    padding-left: 10px;
    color: red;
  }

  input {
    background: url(//cdn.cnchire.com.au/dashboard/calendar-icon.svg) no-repeat
      #f7f7f7;
    margin-top: 9px;
    width: 417px;
    height: 42px;
    padding-left: 15px;
    background-position: 98% 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
  }

  button.btn {
    top: 100px !important;
    color: #fff;
    right: 0px;
    position: absolute;
    width: 113px;
    height: 50px;
    background: #ff8c3f;
    border-radius: 3px;
  }

  .clear {
    background: #f2ebc8 !important;
    right: 122px !important;
    color: #090808 !important;
  }

  .DayPicker {
    margin-top: 5px;
    width: 420px !important;
    position: absolute;
    z-index: 887 !important;
    border: 1px solid #eee;
    background: #ededed;
    border-radius: 8px;
    .DayPicker-Caption {
      font-size: 16px;
      color: #333;
    }
    .DayPicker-Weekdays * {
      font-size: 16px;
      color: #333;
    }
    .DayPicker-Weekdays {
      background: url(//cdn.cnchire.com.au/dashboard/calendar-top-line.svg)
        no-repeat;
      background-position-y: 50px;
      background-position-x: 15px;
    }

    .DayPicker-Body * {
      color: #8598ad;
    }

    .DayPicker-NavButton {
      width: 31.25px;
      height: 31.25px;
      border-radius: 50%;
    }

    .DayPicker-Day {
      background: #ededed;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      position: relative;
      background: url(//cdn.cnchire.com.au/dashboard/calendar-circle.svg)
        no-repeat center center;
      color: #fff !important;
    }

    .DayPicker-Day:hover {
      color: #fff !important;
      background: url(//cdn.cnchire.com.au/dashboard/calendar-circle.svg)
        no-repeat center center #ededed !important;
    }

    .DayPicker-Day.DayPicker-Day--outside:hover {
      background: none !important;
    }

    .DayPicker-NavButton--prev {
      background: url(//cdn.cnchire.com.au/dashboard/calendar-arrow-prev.svg)
        no-repeat;
    }

    .DayPicker-NavButton--next {
      background: url(//cdn.cnchire.com.au/dashboard/calendar-arrow-next.svg)
        no-repeat;
    }
  }
}

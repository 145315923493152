.vehicleDetailsContainer {
  .MuiFormGroup-root {
    padding-top: 20px;
    padding-left: 40px !important;
  }

  .line-small {
    padding-left: 10px;
    font-size: 12px;
  }

  .line-small.top-line {
    border-top: 1px solid #ffffff42 !important;
    border-bottom: 1px solid #ffffff36 !important;
  }

  .line-big {
    border-top: 1px solid #ffffff42 !important;
    margin-bottom: 50px;
  }
  .line-medium {
    padding-left: 10px;
    font-size: 14px;
  }

  .error-container {
    padding-left: 25px;
  }

  margin-top: 50px;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .react-datepicker * {
    color: #fff;
    background: #354682 !important;
  }
  .invalid-range {
    font-size: 20px;
    color: red;
  }

  .vehicleTabs {
    padding: 0px;
    position: relative !important;
    float: left !important;
    border: none;

    li {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 13px;

      display: block;
      float: left;
      height: 50px !important;
      border: none;
      padding-right: 15px;
      color: #a9b5c1;
      border-bottom: 3px solid #e6eaed;
      cursor: pointer;
      .rating-tab-container {
        position: absolute !important;
        margin-top: -20px;
        margin-left: 105px;
      }
    }
    li.rating-tab {
      width: 180px;
    }
  }

  .vehicleTabs li.active {
    color: #2f3073;
    border-color: #fe8c3f;
  }

  .vehicleDetailsSectionTitle {
    margin-top: 30px;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    color: #2f3073;
    text-transform: uppercase;
    width: 100%;
    display: block;
    height: 50px;
    font-weight: 650;
    border-bottom: 3px solid #fe8c3f !important;
  }

  .vehicleDetailsCollapseButton {
    border: #fe8c3f 1px solid;
    border-radius: 5px;
  }
  .vehicleDetailsCollapseButton:focus {
    border: #fe8c3f 1px solid;
    border-radius: 5px;
  }
  .vehicleDetailsCollapseButton * {
    color: #fe8c3f !important;
  }
}

.vehicleDetailsDivider {
  opacity: 0.15;
  width: 100%;
  margin-bottom: 5%;
}
.vehicleDetailsBox {
  display: flex;
  position: relative;
  width: 70%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vehicleDetailsColumnsBox {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.vehicleDetailsColumn1 {
  width: 55%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.vehicleDetailsColumn2 {
  width: 40%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.vehicleDetailsName {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 45px;
  color: #384a5e;
}

.vehicleDetailsBoxIcon {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.vehicleDetailsIconLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  opacity: 0.5;
}

.vehicleDetailsSectionBox {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.vehicleDetailsSectionTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

.vehicleDetailsSectionContent {
  margin-top: 1%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.vehicleDetailsTable {
  width: 100%;
}

.vehicleDetailsTableRow {
  padding-top: 2%;
  padding-bottom: 2%;

  display: flex;
  position: relative;
  width: 100%;
}

.vehicleDetailsTableData {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #384a5e;

  display: flex;
  position: relative;
  width: 45%;
}

.vehicleDetailsTableDataLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  color: #384a5e;
  margin-right: 5%;
}

.vehicleDetailsSectionRow {
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 2%;
  padding-bottom: 2%;
}

.vehicleDetailsSectionRowContent {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.vehicleDetailsSectionRowNote {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #000000;
  opacity: 0.6;
}

.vehicleDetailsCalendarBox {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 5% 5% 0px 5%;
}

.vehicleDetailsPriceBox {
  display: flex;
  position: relative;
  width: 90%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.dateChooseBox {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 5%;
  margin-bottom: 5%;
}

.dateChooseBoxLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-right: 15%;
}

.dateChooseBoxButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-decoration-line: underline;
  color: #000000;
  opacity: 0.5;
}

.vehicleDetailsCalendar {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.additionalAmenitiesItem {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.additionalAmenitiesItemLabel {
  @extend .vehicleDetailsSectionContent;
  margin-left: 5%;
}

.paymentOptionBox {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.payButtonHalfSized {
  width: 47%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #ff8c3f;
  border-radius: 3px;
  border: none;
  padding: 3%;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  text-align: center;
  color: #ffffff;
}

.paymentTypeRadio {
  margin-right: 5%;
}

.vehicleDetailsButtonFullSized {
  @extend .payButtonHalfSized;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.additionalFeesAndChargeBox {
  @extend .vehicleDetailsSectionContent;
  margin-left: 15%;
}

.enquiryButtonLink {
  width: 100%;
}

.vehicleDetailsCollapseButton {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 8px;

  padding: 2%;
  margin-bottom: 2%;
  flex-direction: row;
}

.vehicleDetailsCollapseButtonLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.additionalTermsList {
  margin-top: 1%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
  margin-left: 10%;
}

.DayPicker {
  font-size: 1.2rem;
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPicker-NavButton--prev {
  margin-right: 2.5em;
  background-image: url('//cdn.cnchire.com.au/prevMonth.png');
}

.DayPicker-NavButton--next {
  background-image: url('//cdn.cnchire.com.au/nextMonth.png');
}

.DayPicker-NavButton {
  top: 0.5em;
  width: 2em;
  height: 2em;
  background-size: 100%;
}

.DayPicker-Weekday {
  padding: 0.95em;
}

.dateFromToBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 5%;
}

.dateFromToLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 3%;
}

.dateFromToButton {
  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 8px;

  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3%;
}

.dateFromToButtonLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  opacity: 0.6;
}

.dateFromToNote {
  width: 100%;
  margin-top: 5%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #ff5454;
}

.bookingCostLabel {
  width: 100%;
  margin-top: 5%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.totalCostLabel {
  width: 100%;
  margin-top: 5%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.vehicleDetailsSpecificTermsRow {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.vehicleDetailsSpecificTermsLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  color: #384a5e;
  width: 35%;
}
.vehicleDetailsSectionAmenitiesBox {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.vehicleDetailsSectionAmenitiesBoxItem {
  @extend .vehicleDetailsSectionContent;
  width: 50%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.writeReviewBox {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.writeReviewTitle {
  @extend .vehicleDetailsSectionTitle;
  font-size: 20px;
  margin-right: 5%;
  margin-bottom: 1%;
}
.userRating {
  font-size: 14px;
}
.writeReviewBox2 {
  @extend .writeReviewBox;
  flex-direction: column;
}
.writeReviewForm {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
}
.submitCommentButton {
  width: 25%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #ff8c3f;
  border-radius: 3px;
  border: none;
  padding: 1%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  text-align: center;
  color: #ffffff;
  margin-top: 3%;
}

.additionalfeesbox {
  background: #f2efef;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px 30px 40px 40px;
  .vehicleDetailsSectionTitle {
    border: none !important;
  }
  span:not(.vehicleDetailsSectionTitle),
  li {
    font-size: 14px;
    color: #000;
  }
  ul {
    margin: 0px;
    padding-left: 20px;
  }
  li {
    list-style: none;
  }
  li svg {
    color: #fe8c3f !important;
    margin-right: 10px;
    font-size: 10px;
  }
  .vehicleDetailsCollapseButtonLabel {
    font-weight: 400 !important;
    font-size: 14px;
    text-transform: uppercase !important;
  }
}

.bookingBox {
  position: relative;
  border-radius: 5px !important;
  background: #2f3073;
  width: 100%;
  .dateFromToButtonLabel {
    color: #000 !important;
    text-transform: uppercase;
  }
  .dateFromToButtonIcon {
    color: #000 !important;
  }

  .datepicker-container.startdate {
    margin-top: 200px;
  }
  .datepicker-container.enddate {
    margin-top: 320px;
  }

  .datepicker-container {
    margin-top: 16%;
    margin-left: -39%;
    width: 215px;
    height: 240px;
    position: absolute;
    border-radius: 15px;
    .arrow-datepicker * {
      color: #bcbcbc;
    }
    .arrow-datepicker {
      color: #c4d6ff;
      font-size: 40px;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 20px;
      right: -35px;
    }
    background: none !important;
    width: 50% !important;
    position: absolute !important;
    top: 30% !important;
    margin-left: 25px !important;
    left: 0px !important;
    z-index: 200;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day.react-datepicker__day--disabled:hover {
    background: #bcbcbc1f !important;
    cursor: default !important;
    color: #000;
  }

  .react-datepicker__day--excluded,
  .react-datepicker__day.react-datepicker__day--excluded:hover {
    cursor: default !important;
    color: #000;
  }

  .react-datepicker__day {
    cursor: pointer;
  }
  .react-datepicker__day:hover {
    background: #c4d6ff !important;
    cursor: pointer;
  }
  .react-datepicker__day--excluded:hover::after {
    position: absolute !important;
    z-index: 10;
    line-height: normal !important;
    background-color: #ffffffc7;
    text-transform: capitalize;
    padding: 5px;
    font-size: 14px;
    content: 'Date Unavailable/Booked';
    margin-top: -40px;
  }

  .react-datepicker__day.react-datepicker__day--disabled.react-datepicker__day--excluded {
    background: red !important;
  }

  .react-datepicker__day--selected {
    border: 1px solid #fff !important;
  }
  .react-datepicker {
    font-size: 1em !important;
  }
  .react-datepicker__header {
    padding-top: 0.8em !important;
  }
  .react-datepicker__month {
    margin: 0.4em 1em !important;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em !important;
    line-height: 1.9em !important;
    margin: 0.166em !important;
  }
  .react-datepicker__current-month {
    font-size: 1em !important;
  }
  .react-datepicker__navigation {
    top: 1em !important;
    line-height: 1.7em !important;
    border: 0.45em solid transparent !important;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc !important;
    left: 1em !important;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc !important;
    right: 1em !important;
    left: 320px !important;
  }

  .react-datepicker__time-container {
    width: 6em !important;
  }
  .react-datepicker-time__header {
    font-size: 1em !important;
  }
  .react-datepicker-time__header--time {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .react-datepicker__time-box {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
  }
  .react-datepicker__time-list {
    padding: 0 !important;
  }

  .paymentOptionBox {
    width: 100%;
    display: block;
    padding: 20px;

    button.PaymentType {
      border: 1px solid #fe8c3f;
      width: 44%;
      padding: 10px;
      margin: 10px;
      border-radius: 5px;
      background: #2f3073;
      color: #fe8c3f;
    }

    button.PaymentType.active {
      background: #fe8c3f;
      color: #fff;
    }
  }

  .vehicleDetailsButtonFullSized {
    width: 90%;
    margin: 10px auto 30px auto;
    background: #fe8c3f !important;
    border-radius: 5px;
  }

  .vehicleDetailsButtonFullSized.disabled {
    opacity: 0.7;
  }

  .bookingBoxHeader {
    background: #fe8c3f;
    padding-top: 10px !important;
  }

  .bookingBoxHeader .vehicleDetailsSectionTitle {
    position: relative;
    margin-top: 0px !important;
    height: 15px;
    font-weight: 500;
    font-size: 20px;
    color: #384a5e;
    color: #fff;
    text-align: center;
  }

  .bookingBoxHeader .vehicleDetailsSectionContent {
    color: #fff;
    text-align: center;
    font-size: 10px;
    padding-bottom: 10px;
  }

  * {
    color: #fff;
  }
}

.vehicle-basic-info {
  margin-top: -10px;
  width: 100%;
  color: #384a5e;

  .vehicle-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: #2f3073;
    font-size: 22pt;
    text-transform: uppercase;
  }
  .vehicle-location {
    margin-bottom: 8px;
  }
  .vehicle-type {
    margin-bottom: 10px;
  }

  .vehicle-location svg,
  .vehicle-type svg {
    opacity: 0.7;
  }
  .vehicle-rating {
    font-size: 12px;
    font-weight: bold;

    .caption {
      position: absolute;
      margin-left: 10px;
      margin-top: -2px;
    }
  }

  .vehicle-description {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .vehicle-price-box {
    border-radius: 5px !important;
    color: #fff;
    background: #2f3073;
    display: block;
    height: 100px;
    width: 100%;
    margin-bottom: 50px;
    padding: 5px 20px;
    .vehicle-inner {
      display: inline-block;
      width: 70%;
      margin-left: 16%;
    }

    * {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: bold;
      color: #fff;
    }

    .from {
      font-weight: normal;
      font-size: 14px;
      margin-right: 30px;
    }
    .dollar {
      font-size: 25px;
      color: #fe8c3f !important;
      position: absolute;
      margin-top: 10px;
      margin-left: -18px;
    }
    .amount {
      font-size: 40pt;
    }
    .day {
      font-size: 22px;
    }
  }
}

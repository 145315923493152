.add-form-container {
  width: 1200px;
  height: 500px;
  margin: auto;

  .button-container {
    padding-top: 10px;
  }
  .button-container button {
    float: right;
  }

  .input-item {
    margin-top: 20px;

    input,
    select {
      width: 450px !important;
      padding: 10px;
      border-radius: 10px;
      border: none;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    }

    .ck-editor__editable {
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
      min-height: 300px !important;
    }
  }
}

.user-container {
  width: 99%;
  padding: 20px;
  margin-right: 200px !important;
  background: #fff;

  .send-private-btn {
    float: right;
  }
  .item {
    margin: 10px;
  }
}

.singleFAQContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.singleFAQContentBox {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 5%;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  flex-direction: column;
}

.faqQuestion {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 127.5%;
  color: #384a5e;
  margin-bottom: 4%;
}

.faqAnswer {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
}

.allVehiclesContainer .searchContainer {
	z-index: 1 !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
   .allVehiclesBody {
		width: 100% !important;
		display: table !important;
	}
	.vehicleListPaginationBox, .App {
		width: 100% !important;
		margin-bottom: 0 !important;
	}
	.vehicleListPaginationBox .vehicleListingsContainer .vehicleCardContainer {
		width: calc(33.33% - 30px) !important;
	}
	.vehicleListingsContainer .vehicleCardContainer:nth-child(3n+3) {
		margin-right: 0 !important;
	}
	.vehiclePaginationContainer {
		margin-top: 0 !important;
	}
	.App {
		height: 400px !important;
	}
	.vehicleListingsContainer .vehicleCardContainer .MuiPaper-root {
		height: 460px;
	}
	.vehicleListingsContainer .vehicleCardContainer .vehicleCardPriceBox {
		margin-top: 40px;
	}
	.vehiclePaginationContainer {
		display: table !important;
		margin: 0 auto 20px !important;
	}

}

@media (min-width: 768px) and (max-width: 991px) {

	.allVehiclesBody {
		width: 100% !important;
		display: table !important;
	}
	.vehicleListPaginationBox, .App {
		width: 100% !important;
		margin-bottom: 0 !important;
	}
	.vehicleListPaginationBox .vehicleListingsContainer .vehicleCardContainer {
		width: calc(33.33% - 32px) !important;
	}
	.vehicleListingsContainer .vehicleCardContainer:nth-child(3n+3) {
		margin-right: 0 !important;
	}
	.vehiclePaginationContainer {
		margin-top: 0 !important;
	}
	.App {
		height: 400px !important;
	}
	.vehicleListingsContainer {
		width: 100% !important;
	}		
	.vehicleListingsContainer .vehicleCardContainer .MuiPaper-root {
		height: 460px; width: 100%;
	}
	.vehicleListingsContainer .vehicleCardContainer .vehicleCardPriceBox {
		margin-top: 40px;
	}
	.vehiclePaginationContainer {
		display: table !important;
		margin: 0 auto 20px !important;
	}
	.allVehiclesContainer {
		padding: 0 25px;
	}

}

@media (max-width: 767px) {

	.pageBannerContainer {
		margin-top: 25px !important;
	}
	.pageBannerContainer .pg-banner {
		margin-left: 0 !important;
		width: 100%;
		padding: 0 25px;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		margin-top: 0;
		margin-bottom: 0;
	}
	.pageBannerContainer .pg-banner-title {
		font-size: 35px;
	}
	.allVehiclesBody {
		width: 100% !important;
		display: table !important;
	}
	.vehicleListPaginationBox, .App {
		width: 100% !important;
		margin-bottom: 0 !important;
	}
	.vehicleListPaginationBox .vehicleListingsContainer .vehicleCardContainer {
		width: calc(50% - 18px) !important;
	}
	.vehicleListPaginationBox .vehicleListingsContainer .vehicleCardContainer:nth-child(2n) {
		margin-right: 0 !important;
	}
	.vehicleListingsContainer .vehicleCardContainer:nth-child(3n+3) {
		margin-right: 5% !important;
	}
	.vehiclePaginationContainer {
		margin-top: 0 !important;
	}
	.App {
		height: 400px !important;
	}
	.vehicleListingsContainer {
		width: 100% !important;
	}		
	.vehicleListingsContainer .vehicleCardContainer .MuiPaper-root {
		height: 460px; width: 100%;
	}
	.vehicleCardContainer .vehicleCardNameBox {
    	height: auto !important;
	}
	.vehiclePaginationContainer {
		display: table !important;
		margin: 0 auto 20px !important;
	}
	.allVehiclesContainer {
		padding: 0 25px;
	}
	.allVehiclesTitle {
		margin-top: 0 !important;
		font-size: 25px !important;
		width: 100% !important;
	}
	.allVehiclesContainer .searchContainer {
		width: 100% !important;
	}

}

@media (max-width: 480px) {

	.vehicleListPaginationBox .vehicleListingsContainer .vehicleCardContainer {
		width: 100% !important;
		margin: 0 0 25px !important;
	}
	.MuiPagination-root .MuiPaginationItem-sizeLarge {
		min-width: 24px !important;
		height: auto !important;
	}
	.pageBannerContainer .pg-banner-title {
		font-size: 30px;
	}
	.vehicleListingsContainer .vehicleCardContainer .MuiPaper-root {
		height: auto;
	}

}
.filter-selection {
    margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    
    .dashboard-owners-page table thead {
        display: none;
    }
    .dashboard-owners-page table tr {
	    display: grid;
	    text-align: center;
	    padding: 20px 0;
    }
    
}

@media (min-width: 768px) and (max-width: 991px) {
    
    .dashboard-owners-page table thead {
        display: none;
    }
    .dashboard-owners-page table tr {
	    display: grid;
	    text-align: center;
	    padding: 20px 0;
    }
    
}

@media (max-width: 767px) {

    .dashboard-owners-page table thead {
        display: none;
    }
    .dashboard-owners-page table tr {
	    display: grid;
	    text-align: center;
	    padding: 20px 0;
    }
    
}

@media (max-width: 480px) {

}
// @import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');
.checkoutContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.billingcolumn {
  display: flex;
  position: relative;
  width: 55%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.billingTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  color: #384a5e;
  margin-bottom: 5%;
}

.billingrow {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.billingrowItem {
  width: 48%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 3%;
}

.billingrowItem2 {
  @extend .billingrowItem;
  width: 100%;
}

.billingfield {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 127.5%;
  color: #000000;
}

.billingInput {
  margin-top: 2%;
  height: 50px;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  padding: 2%;
  padding-left: 20px;
}

.billingTextArea {
  margin-top: 2%;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  padding: 2%;
  padding-left: 20px;
}

.billingcolumn2 {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 42%;

  background: rgba(224, 235, 237, 0.4);
  border: 1px solid #ff8c3f;
  box-sizing: border-box;
  border-radius: 3px;

  padding: 2%;
}

.billingcolumncontainer {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 70%;
  margin-top: 30px;
}

.yourOrderTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  // line-height: 127.5%;
  /* identical to box height, or 32px */

  color: #384a5e;
}

.checkoutLine {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 5%;
  margin-bottom: 5%;
}

.checkoutTable {
  width: 100%;
}

.tableColumn1 {
  width: 80%;
}

.tableColumn2 {
  width: 20%;
}

.tdTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  // line-height: 127.5%;
  /* or 25px */

  color: #384a5e;
}

.tdField {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 169% */

  color: #000000;
}

.tdPrice {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  /* identical to box height, or 135% */

  text-transform: lowercase;

  color: rgba(0, 0, 0, 0.7);
}

.tdFieldData {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */

  // text-transform: lowercase;

  color: rgba(0, 0, 0, 0.7);
}

.tdField2 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 127.5%;
  /* or 25px */

  color: #384a5e;
}

.creditCardTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 127.5%;
  /* or 25px */

  color: #384a5e;
}

.ccTitleBox {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5%;
}

.paymentDetails {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */

  color: rgba(0, 0, 0, 0.7);

  margin-top: 10%;
}

.ccDebitCard {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 169% */

  color: #000000;
  margin-top: 10%;
  margin-bottom: 3%;
}

.imOIjz input#cardNumber {
  width: 20em !important;
}

.term1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */

  color: rgba(0, 0, 0, 0.7);
  margin-top: 10%;
}

.proceedToCheckoutBtnContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10%;
  width: 100%;
}

.proceedToCheckoutBtn {
  background: #ff8c3f;
  box-shadow: 7px 5px 23px rgba(255, 140, 63, 0.2);
  border-radius: 3px;
  padding: 5%;
  border: none;
  outline: none;
}

.proceedToCheckoutBtnLabel {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.5%;
  /* or 20px */

  text-align: center;

  color: #ffffff;
}

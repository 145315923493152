.latestNewsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.newsContainer {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 3%;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75%;
  flex-wrap: wrap;
}

.MuiPaginationItem-outlined {
  background: #ffffff;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.12);
  border: none;
}

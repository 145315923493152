.support-container {
  background: #fff;
  width: 99%;
  padding: 10px;

  .message {
    width: 500px;
    height: 300px;
  }

  .success-message {
    font-size: 20px;
    height: 500px;
    text-align: center;
    padding: 50px;
  }
}

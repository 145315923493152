.newsCardContainer {
  display: flex;
  position: relative;
  margin-left: 4%;
  margin-bottom: 5%;

  .MuiPaper-root {
    height: 450px !important;
  }
}

.dateNewsContainer {
  background: #ff8c3f;
  border-radius: 46px;
  width: 45%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: -4%;
  padding: 1%;
  margin-left: 4%;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.newsReadMore {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.readMoreText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
  opacity: 0.6;
  padding-left: 2.5%;
}

.doubleRightCircle {
  display: flex;
  position: relative;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  background: #e8efff;
}

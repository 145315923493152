@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vehicleDetailsBox {
    	width: 100% !important;
    	padding: 0 25px;
    }
    .vehicleDetailsBox .vehicleBannerContainer .thumbnails-container .thumb-item {
    	object-fit: cover !important;
    	height: 60px !important;
	}
}

@media (min-width: 768px) and (max-width: 991px) {

	.vehicleDetailsBox {
    	width: 100% !important;
    	padding: 0 25px;
    }
    .vehicleDetailsBox .vehicleBannerContainer .thumbnails-container .thumb-item {
    	object-fit: cover !important;
    	height: 60px !important;
	}

}

@media (max-width: 767px) {

    .vehicleDetailsContainer {
        margin-top: 25px;
    }
	.vehicleDetailsBox .vehicleBannerContainer .slide-banner-container {
	    width: 100% !important;
	}
	.vehicleDetailsBox {
    	width: 100% !important;
    	padding: 0 25px;
    }
    .vehicleDetailsBox .vehicleBannerContainer .thumbnails-container .thumb-item {
    	object-fit: cover !important;
    	height: 60px !important;
	}
	.vehicleDetailsColumnsBox {
    	display: table !important;
	}
	.vehicleDetailsColumn1, .vehicleDetailsColumn2 {
    	width: 100% !important;
    	display: table !important;
	}
	.vehicleDetailsContainer .vehicleTabs li {
    	padding-top: 10px;
	}
    .vehicleDetailsColumnsBox {
        display: flex !important;
        flex-direction: column !important;
    }
    .vehicleDetailsColumn1 {
        order: 2;
    }
    .vehicleDetailsColumn2 {
        order: 1;
        margin-bottom: 25px;
    }
    .additionalfeesbox li {
        list-style: inherit !important;
    }
    .vehicle-detail-page .vehicleDetailsSectionTitle {
        height: auto !important;
    }

}

@media (max-width: 480px) {
	/*.vehicleDetailsTable tr.vehicleDetailsTableRow {
    	display: flow-root;
	}*/
	span.vehicleDetailsSectionAmenitiesBoxItem {
    	width: 100%;
	}
	.vehicle-basic-info .vehicle-price-box .vehicle-inner {
    	margin-left: 0 !important;
    	width: 100% !important;
	}
	.paymentButtons button.PaymentType {
    	width: 100% !important;
	    margin: 0 0 10px !important;
	}
	.vehicleDetailsContainer .vehicleDetailsSectionTitle, .vehicleDetailsContainer .writeReviewTitle {
    	height: auto !important; margin-top: 0 !important;
	}
	.additionalfeesbox {
    	padding: 25px !important;
	}
    .vehicleDetailsColumnsBox {
        display: flex !important;
        flex-direction: column !important;
    }
    .vehicleDetailsColumn1 {
        order: 2;
    }
    .vehicleDetailsColumn2 {
        order: 1;
        margin-bottom: 25px;
    }
}
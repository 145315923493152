.newsPostContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5%;
}

.newsImageContainer {
  display: flex;
  position: relative;
  margin-top: 30px;
  margin-bottom: 3%;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.newsImage {
  width: 100%;
}

.newsContent {
  display: flex;
  position: relative;
  width: 70%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.newsContentDate {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 3%;
}

.newsContentTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 3%;
}

.newsContentParagraph {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 3%;
}

.newsContentSocicalMedia {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 20%;
}

.partnersAndMediaContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  // background: red;
  padding-left: 12%;
  padding-right: 12%;
}

.partnersAndMediaTitle {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 145%;
  text-align: center;
  color: #384a5e;
  margin-top: 3%;
  margin-bottom: 2.5%;
}

.partnersAndMediaTextBox {
  width: 90%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.partnersAndMediaText1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sliderContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-bottom: 5%;
}

.partnerAndMediaItem {
  // width: 50%;
  margin: 15%;
}

.partnerAndMediaIcon {
  width: 100%;
}
